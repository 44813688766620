import React from 'react';
import './Checkbox.scss';

type CheckboxProps = {
  label: string;
  btnText: string;
  handleClick: () => void;
  handleChange: () => void;
  isDisabled: boolean;
  isChecked: boolean;
  name: string;
  className?: string;
  isSaved?: boolean;
};

const Checkbox = ({
  label,
  btnText,
  handleClick,
  handleChange,
  isDisabled,
  isChecked,
  name,
  className = '',
  isSaved = false,
}: CheckboxProps) => {
  const uniqueName = name + '-' + Math.random().toString(16).slice(2);

  return (
    <div className={`checkbox ${className}`}>
      {isSaved && <span className={'checkbox_save'}>(saved)</span>}
      <label htmlFor={uniqueName} className={'checkbox_label'}>
        {label}
      </label>
      <input
        type="checkbox"
        name={uniqueName}
        id={uniqueName}
        checked={isChecked}
        onChange={handleChange}
        className={'checkbox_input'}
      />
      <button type="button" onClick={handleClick} className={'checkbox_btn'} disabled={isDisabled}>
        {btnText}
      </button>
    </div>
  );
};

export default Checkbox;
