import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import './SendInvite.scss';
import Button from '../_common/Button/Button';
import { initialValuesSendLink } from '../../constants/defaultData';
import validate from '../../constants/validate';

interface Props {
  sendLinkHandler: (email: string) => Promise<{ token?: string }>;
}

function SendInvite({ sendLinkHandler }: Props) {
  const [emailSentText, setEmailSentText] = useState<string>('');

  return (
    <Formik
      initialValues={initialValuesSendLink}
      onSubmit={async (values, actions) => {
        const data = await sendLinkHandler(values.email);

        if (!data.token) {
          setEmailSentText('Something went wrong');
        } else {
          setEmailSentText('Email sent');
        }

        actions.resetForm();
      }}
    >
      {({ errors }) => {
        return (
          <Form className={'send_invite'}>
            <div className={'send_invite-container'}>
              <div className="send_invite-input_wrapper">
                <Field
                  type={'email'}
                  className={`send_invite-input ${errors.email ? 'error' : ''}`}
                  name={'email'}
                  placeholder={'Enter your email'}
                  validate={validate}
                />
              </div>
              <Button className={'send_invite_button'} type={'submit'} title={'Send Invite'} />
            </div>
            {emailSentText.length !== 0 && <div className={'send_invite-info'}>{emailSentText}!</div>}
          </Form>
        );
      }}
    </Formik>
  );
}

export default SendInvite;
