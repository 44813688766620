import { CurrentEmployee, InvitedEmployee } from '../../types/employee';
import { ActionInterface } from '../../types/common';
import EMPLOYEE_ACTION_TYPES from '../actionTypes/employeeActionTypes';
import { employeeReducerDefaultState } from '../../constants/defaultData';

export interface EmployeeReducer {
  currentEmployee: CurrentEmployee[];
  invitedEmployee: InvitedEmployee[];
}

function employeeReducer(state: EmployeeReducer = employeeReducerDefaultState, { data, type }: ActionInterface) {
  switch (type) {
    case EMPLOYEE_ACTION_TYPES.GET_INVITED_EMPLOYEE: {
      return { ...state, invitedEmployee: data };
    }

    case EMPLOYEE_ACTION_TYPES.GET_CURRENT_EMPLOYEE: {
      return { ...state, currentEmployee: data };
    }

    case EMPLOYEE_ACTION_TYPES.REVOKE_INVITE: {
      console.log(data, 'data');
      const deleteInvitation = state.invitedEmployee.filter(({ TOKEN }) => TOKEN !== data);

      return { ...state, invitedEmployee: deleteInvitation };
    }
  }

  return state;
}

export default employeeReducer;
