import React from 'react';
import './SpecificRoomPage.scss';
import LineChart from '../../components/LineChart/LineChart';
import { Field, Form, Formik, FormikErrors, FormikValues } from 'formik';
import useCharts from '../../hooks/useCharts';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import getLocalDate from '../../utilsCommon/UtilsCommon';
import Button from '../../components/_common/Button/Button';
import ListChart from '../../components/ListChart/ListChart';
import { tableHeadings } from '../../constants/defaultData';

const validate = (values: FormikValues) => {
  const errors: FormikErrors<FormikValues> = {};

  if (!values.startDate) {
    errors.startDate = 'Required';
  } else if (!values.endDate) {
    errors.endDate = 'Required';
  }

  return errors;
};

function SpecificRoomPage() {
  const { adminRoomVisits, adminNftSoldSum, adminNftSoldCount, adminNftClicks } = useSelector(
    (state: ApplicationState) => state.charts
  );
  const { ROOM_ID } = useSelector((state: ApplicationState) => state.rooms.specificRoom);
  const { getAdminRoomVisits, getAdminNftSoldSum, getAdminNftSoldCount, getAdminNftClicks } = useCharts();

  const adminRoomVisitsData = () => adminRoomVisits.map(({ visits }) => visits);
  const adminRoomVisitsLabels = () => adminRoomVisits.map(({ date }) => getLocalDate(date));
  const adminNftSoldSumData = () => adminNftSoldSum.map(({ count }) => count);
  const adminNftSoldSumLabels = () => adminNftSoldSum.map(({ date }) => getLocalDate(date));
  const adminNftSoldCountData = () => adminNftSoldCount.map(({ count }) => count);
  const adminNftSoldCountLabels = () => adminNftSoldCount.map(({ date }) => getLocalDate(date));

  const rangeInputs = () => {
    return (
      <Formik
        initialValues={{ startDate: '', endDate: '' }}
        validate={validate}
        onSubmit={(values) => {
          getAdminRoomVisits({
            roomId: ROOM_ID,
            beforeDate: values.startDate.toString(),
            afterDate: values.endDate.toString(),
          });
          getAdminNftSoldSum({
            roomId: ROOM_ID,
            beforeDate: values.startDate.toString(),
            afterDate: values.endDate.toString(),
          });
          getAdminNftSoldCount({
            roomId: ROOM_ID,
            beforeDate: values.startDate.toString(),
            afterDate: values.endDate.toString(),
          });
          getAdminNftClicks({
            roomId: ROOM_ID,
            beforeDate: values.startDate.toString(),
            afterDate: values.endDate.toString(),
          });
        }}
      >
        {({ handleChange, values, dirty, isValid }) => {
          return (
            <Form className={'overview-form'}>
              <p>From</p>
              <p>To</p>
              <span />
              <Field
                type={'date'}
                className={'form_input'}
                onChange={handleChange('startDate')}
                value={values.startDate}
                name={'startDate'}
              />
              <Field
                name={'endDate'}
                type={'date'}
                className={'form_input'}
                onChange={handleChange('endDate')}
                value={values.endDate}
              />
              <Button className={'dateConfirm'} title={'Apply'} type={'submit'} disabled={!(dirty && isValid)} />
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <>
      <div className={'specific-room-page'}>
        <h2 className="specific-room-page-main-title">Room Analytics Showcasing:</h2>
        <div className={'specific-room-page-header'}>
          <h2>Range Selection</h2>
          <div className={'range-container'}>{rangeInputs()}</div>
        </div>
        <div className="specific-room-page-lists">
          <ListChart
            name={'Most Clicked Frame/NFT'}
            borderColor={'#e6e8ec'}
            headColor={'#f4f5f6'}
            headTextColor={'#000000'}
            tableHeadings={tableHeadings}
            list={adminNftClicks}
          />
        </div>
        <div className={'specific-room-page-charts'}>
          <LineChart
            color={'#9757D7'}
            title={'Number of users visited the space'}
            data={adminRoomVisitsData()}
            labels={adminRoomVisitsLabels()}
          />
        </div>
      </div>

      <div className={'specific-room-page'}>
        <h2 className="specific-room-page-main-title">Sales Analytics:</h2>
        <div className={'specific-room-page-header'}>
          <h2>Range Selection</h2>
          <div className={'range-container'}>{rangeInputs()}</div>
        </div>
        <div className={'specific-room-page-charts'}>
          <LineChart
            color={'#EF466F'}
            title={'Total Sales'}
            data={adminNftSoldCountData()}
            labels={adminNftSoldCountLabels()}
          />
          <LineChart
            color={'#9757D7'}
            title={'Post sales/transactions'}
            data={adminNftSoldSumData()}
            labels={adminNftSoldSumLabels()}
          />
        </div>
      </div>
    </>
  );
}

export default SpecificRoomPage;
