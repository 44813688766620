import React, { useEffect, useState } from 'react';
import './OverViewPage.scss';
import LineChart from '../../components/LineChart/LineChart';
import { Field, Form, Formik, FormikErrors, FormikValues } from 'formik';
import useCharts from '../../hooks/useCharts';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import getLocalDate from '../../utilsCommon/UtilsCommon';
import Button from '../../components/_common/Button/Button';
import { ReactComponent as Icon } from '../../assets/static/svg/analytics_active.svg';

import CardChart from '../../components/CardChart/CardChart';
import ListChart from '../../components/ListChart/ListChart';
import { tableHeadingsMostClickedNft, tableHeadingsRevenueRooms } from '../../constants/defaultData';

const validate = (values: FormikValues) => {
  const errors: FormikErrors<FormikValues> = {};

  if (!values.startDate) {
    errors.startDate = 'Required';
  } else if (!values.endDate) {
    errors.endDate = 'Required';
  }

  return errors;
};

function OverViewPage() {
  const {
    usersCreated,
    roomsCreated,
    nftSold,
    nftPlaced,
    accessPassSearch24,
    accessPassSearchMonth,
    accessPassSearchYear,
    totalAccessPassSearch,
    freeTrailUsersSearch,
    dailyUniqueUsers,
    monthlyUniqueUsers,
    adminMostRevenueRooms,
    adminMostClickedNft,
    accessPassSearchLegend,
    adminNftSoldSumPerDay,
  } = useSelector((state: ApplicationState) => state.charts);
  const {
    getNumberOfUsers,
    getNumberOfSoldNft,
    getNumberOfRooms,
    getNumberOfPlacedNFT,
    getNonLoggedInUsers,
    getLiveUsers,
    getAccessPassSearch24,
    getAccessPassSearchMonth,
    getAccessPassSearchYear,
    getTotalAccessPassSearch,
    getFreeTrailUsersSearch,
    getDailyUniqueUsers,
    getMonthlyUniqueUsers,
    getAdminMostRevenueRooms,
    getAdminMostClickedNft,
    adminTotalRevenue,
    getAccessPassSearchLegend,
    getAdminNftSoldSumPerDay,
  } = useCharts();

  const [numberInactiveUsers, setNumberInactiveUsers] = useState(0);
  const [numberLiveUsers, setNumberLiveUsers] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);

  const userData = () => usersCreated.map(({ COUNT }) => COUNT);
  const userLabels = () => usersCreated.map(({ CREATED_DATE }) => getLocalDate(CREATED_DATE));
  const roomsData = () => roomsCreated.map(({ COUNT }) => COUNT);
  const roomsLabels = () => roomsCreated.map(({ CREATED_DATE }) => getLocalDate(CREATED_DATE));
  const nftData = () => nftSold.map(({ NUMOFSOLD }) => NUMOFSOLD);
  const nftLabels = () => nftSold.map(({ DATE }) => getLocalDate(DATE));
  const nftPlacedData = () => nftPlaced.map(({ COUNT }) => COUNT);
  const nftPlacedLabels = () => nftPlaced.map(({ ACTION_DATE }) => getLocalDate(ACTION_DATE));
  const accessPass24hoursData = () => accessPassSearch24.map(({ count }) => count);
  const accessPass24hoursLabels = () => accessPassSearch24.map(({ date }) => getLocalDate(date));
  const accessPassMonthData = () => accessPassSearchMonth.map(({ count }) => count);
  const accessPassMonthLabels = () => accessPassSearchMonth.map(({ date }) => getLocalDate(date));
  const accessPassYearData = () => accessPassSearchYear.map(({ count }) => count);
  const accessPassYearLabels = () => accessPassSearchYear.map(({ date }) => getLocalDate(date));
  const totalAccessPassData = () => totalAccessPassSearch.map(({ count }) => count);
  const totalAccessPassLabels = () => totalAccessPassSearch.map(({ date }) => getLocalDate(date));
  const freeTrailUsersData = () => freeTrailUsersSearch.map(({ count }) => count);
  const freeTrailUsersLabels = () => freeTrailUsersSearch.map(({ date }) => getLocalDate(date));
  const dailyUniqueUsersData = () => dailyUniqueUsers.map(({ count }) => count);
  const dailyUniqueUsersLabels = () => dailyUniqueUsers.map(({ date }) => getLocalDate(date));
  const monthlyUniqueUsersData = () => monthlyUniqueUsers.map(({ count }) => count);
  const monthlyUniqueUsersLabels = () => monthlyUniqueUsers.map(({ month }) => getLocalDate(month));
  const accessPassSearchLegendData = () => accessPassSearchLegend.map(({ count }) => count);
  const accessPassSearchLegendLabels = () => accessPassSearchLegend.map(({ date }) => getLocalDate(date));
  const adminNftSoldSumPerDayData = () => adminNftSoldSumPerDay.map(({ sum }) => sum);
  const adminNftSoldSumPerDayLabels = () => adminNftSoldSumPerDay.map(({ date }) => getLocalDate(date));

  useEffect(() => {
    getNonLoggedInUsers().then(({ count }) => setNumberInactiveUsers(count));
    getLiveUsers().then(({ count }) => setNumberLiveUsers(count));
  }, []);

  const rangeInputs = () => {
    return (
      <Formik
        initialValues={{ startDate: '', endDate: '' }}
        validate={validate}
        onSubmit={(values) => {
          getNumberOfUsers({ beforedate: values.endDate.toString(), afterdate: values.startDate.toString() });
          getNumberOfSoldNft({ todate: values.endDate.toString(), fromdate: values.startDate.toString() });
          getNumberOfRooms({ beforedate: values.endDate.toString(), afterdate: values.startDate.toString() });
          getNumberOfPlacedNFT({ beforedate: values.endDate.toString(), afterdate: values.startDate.toString() });
          getAccessPassSearch24({ beforeDate: values.startDate.toString(), afterDate: values.endDate.toString() });
          getAccessPassSearchMonth({ beforeDate: values.startDate.toString(), afterDate: values.endDate.toString() });
          getAccessPassSearchYear({ beforeDate: values.startDate.toString(), afterDate: values.endDate.toString() });
          getTotalAccessPassSearch({ beforeDate: values.startDate.toString(), afterDate: values.endDate.toString() });
          getFreeTrailUsersSearch({ beforeDate: values.startDate.toString(), afterDate: values.endDate.toString() });
          getDailyUniqueUsers({ beforeDate: values.startDate.toString(), afterDate: values.endDate.toString() });
          getMonthlyUniqueUsers({ beforeDate: values.startDate.toString(), afterDate: values.endDate.toString() });
          getAdminMostRevenueRooms({ beforeDate: values.startDate.toString(), afterDate: values.endDate.toString() });
          getAdminMostClickedNft({ beforeDate: values.startDate.toString(), afterDate: values.endDate.toString() });
          getAccessPassSearchLegend({ beforeDate: values.startDate.toString(), afterDate: values.endDate.toString() });
          getAdminNftSoldSumPerDay({ beforeDate: values.startDate.toString(), afterDate: values.endDate.toString() });
          adminTotalRevenue({ beforeDate: values.startDate.toString(), afterDate: values.endDate.toString() }).then(
            (revenue) => {
              if (revenue?.sum) {
                setTotalRevenue(revenue.sum);
              }
            }
          );
        }}
      >
        {({ handleChange, values, dirty, isValid }) => {
          return (
            <Form className={'overview-form'}>
              <p>From</p>
              <p>To</p>
              <span />
              <Field
                type={'date'}
                className={'form_input'}
                onChange={handleChange('startDate')}
                value={values.startDate}
                name={'startDate'}
              />
              <Field
                name={'endDate'}
                type={'date'}
                className={'form_input'}
                onChange={handleChange('endDate')}
                value={values.endDate}
              />
              <Button className={'dateConfirm'} title={'Apply'} type={'submit'} disabled={!(dirty && isValid)} />
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <div className={'overview'}>
      <div className={'overview-header'}>
        <h2>Range Selection</h2>
        <div className={'range-container'}>{rangeInputs()}</div>
      </div>

      <div className="overview-cards">
        <CardChart
          name={'Number of Users Live Curently'}
          formatNumberType={'number'}
          value={numberLiveUsers}
          icon={Icon}
          color={'green'}
        />
        <CardChart
          name={'Total Revenue in General'}
          formatNumberType={'currency'}
          value={totalRevenue}
          icon={Icon}
          color={'yellow'}
        />
        <CardChart
          name={'Inactive Users Last 3 Months'}
          formatNumberType={'number'}
          value={numberInactiveUsers}
          icon={Icon}
          color={'green'}
        />
      </div>
      <div className="overview-lists">
        <ListChart
          name={'Most Clicked Frame/NFT'}
          borderColor={'#e6e8ec'}
          headColor={'#f4f5f6'}
          headTextColor={'#000000'}
          tableHeadings={tableHeadingsMostClickedNft}
          list={adminMostClickedNft}
        />
        <ListChart
          name={'Most Revenue Generated Rooms'}
          borderColor={'#e6e8ec'}
          headColor={'#f4f5f6'}
          headTextColor={'#000000'}
          tableHeadings={tableHeadingsRevenueRooms}
          list={adminMostRevenueRooms}
        />
      </div>
      <div className={'overview-charts'}>
        <LineChart color={'#EF466F'} title={'Number of Users Created'} data={userData()} labels={userLabels()} />
        <LineChart color={'#9757D7'} title={'Number of Rooms Created'} data={roomsData()} labels={roomsLabels()} />
        <LineChart color={'#45B36B'} title={'Number of NFTs Sold'} data={nftData()} labels={nftLabels()} />
        <LineChart
          color={'#F9C50B'}
          title={'Number of NFTs Placed In Rooms'}
          data={nftPlacedData()}
          labels={nftPlacedLabels()}
        />

        <LineChart
          color={'green'}
          title={'Number of Sales Per Day ($)'}
          data={adminNftSoldSumPerDayData()}
          labels={adminNftSoldSumPerDayLabels()}
        />
        <LineChart
          color={'orange'}
          title={'Total Daily Users'}
          data={dailyUniqueUsersData()}
          labels={dailyUniqueUsersLabels()}
        />
        <LineChart
          color={'red'}
          title={'Total Monthly Active Users'}
          data={monthlyUniqueUsersData()}
          labels={monthlyUniqueUsersLabels()}
        />
        <LineChart
          color={'yellow'}
          title={'Total Free Trail Users'}
          data={freeTrailUsersData()}
          labels={freeTrailUsersLabels()}
        />
        <LineChart
          color={'pink'}
          title={'Total Number of Access Pass Users'}
          data={totalAccessPassData()}
          labels={totalAccessPassLabels()}
        />
        <LineChart
          color={'orange'}
          title={'Total Number of Legend Staking Users'}
          data={accessPassSearchLegendData()}
          labels={accessPassSearchLegendLabels()}
        />

        <LineChart
          color={'pink'}
          title={'24 Hour Pass'}
          data={accessPass24hoursData()}
          labels={accessPass24hoursLabels()}
        />
        <LineChart
          color={'orange'}
          title={'1 Month Rolling'}
          data={accessPassMonthData()}
          labels={accessPassMonthLabels()}
        />
        <LineChart
          color={'yellow'}
          title={'12 Month Fixed'}
          data={accessPassYearData()}
          labels={accessPassYearLabels()}
        />
      </div>
    </div>
  );
}

export default OverViewPage;
