import { LogIn, Registration } from '../types/auth';
import { Http } from '../services/http';
import { API_BASE_URL, API_ENDPOINTS, USER_TOKEN_NAMESPACE } from '../constants/api';
import LocalStorage from '../services/localStorage';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../constants/routerConstants';

const useAuth = () => {
  const navigation = useNavigate();
  const registration = async (data: Registration) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.REGISTRATION}`;

    await Http.post(url, data);

    navigation(ROUTES.LOGIN);
  };

  const logIn = async (data: LogIn) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.LOGIN}`;
    const res = await Http.post(url, data);

    if (res !== undefined) {
      LocalStorage.set(USER_TOKEN_NAMESPACE, res.accessToken);

      return res;
    } else {
      return 'Login Failed';
    }
  };

  const logOut = async () => {
    await LocalStorage.set(USER_TOKEN_NAMESPACE, '');
    navigation(ROUTES.LOGIN);
  };

  const sendResetPasswordLink = async (email: string) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.SEND_RESET_PASSWORD_LINK}`;

    return Http.post(url, { email: email });
  };

  const confirmResetPassword = async (password: string, token: string) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.CONFIRM_RESET_PASSWORD}`;

    await Http.post(
      url,
      {
        password: password,
      },
      { Authorization: `Bearer ${token}` }
    );
  };

  return { logIn, logOut, registration, sendResetPasswordLink, confirmResetPassword };
};

export default useAuth;
