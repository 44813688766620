const CHARTS_ACTION_TYPES = {
  GET_NUMBER_OF_USERS_CREATED: 'GET_NUMBER_OF_USERS_CREATED',
  GET_NUMBER_OF_ROOMS_CREATED: 'GET_NUMBER_OF_ROOMS_CREATED',
  GET_NUMBER_OF_PLACED_NFT: 'GET_NUMBER_OF_PLACED_NFT',
  GET_NFT_SOLD: 'GET_NFT_SOLD',
  GET_ACCESS_PASS_SEARCH_24: 'GET_ACCESS_PASS_SEARCH_24',
  GET_ACCESS_PASS_SEARCH_MONTH: 'GET_ACCESS_PASS_SEARCH_MONTH',
  GET_ACCESS_PASS_SEARCH_YEAR: 'GET_ACCESS_PASS_SEARCH_YEAR',
  GET_TOTAL_ACCESS_PASS_SEARCH: 'GET_TOTAL_ACCESS_PASS_SEARCH',
  GET_FREE_TRAIL_USERS_SEARCH: 'GET_FREE_TRAIL_USERS_SEARCH',
  GET_DAILY_UNIQUE_USERS: 'GET_DAILY_UNIQUE_USERS',
  GET_MONTHLY_UNIQUE_USERS: 'GET_MONTHLY_UNIQUE_USERS',
  GET_ADMIN_ROOM_VISITS: 'GET_ADMIN_ROOM_VISITS',
  GET_ADMIN_NFT_SOLD_SUM: 'GET_ADMIN_NFT_SOLD_SUM',
  GET_ADMIN_NFT_SOLD_COUNT: 'GET_ADMIN_NFT_SOLD_COUNT',
  GET_ADMIN_NFT_CLICKS: 'GET_ADMIN_NFT_CLICKS',
  GET_ADMIN_MOST_REVENUE_ROOMS: 'GET_ADMIN_MOST_REVENUE_ROOMS',
  GET_ADMIN_MOST_CLICKED_NFT: 'GET_ADMIN_MOST_CLICKED_NFT',
  GET_ACCESS_PASS_SEARCH_LEGEND: 'GET_ACCESS_PASS_SEARCH_LEGEND',
  GET_ADMIN_NFT_SOLD_SUM_PER_DAY: 'GET_ADMIN_NFT_SOLD_SUM_PER_DAY',
};

export default CHARTS_ACTION_TYPES;
