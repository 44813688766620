import { Http } from '../services/http';
import { API_BASE_URL, API_ENDPOINTS } from '../constants/api';

const useSettings = () => {
  const getGlobalRoomSize = async () => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.GET_GLOBAL_ROOM_SIZE}`;
    const res = await Http.get(url);

    return res;
  };

  const setGlobalRoomSize = async (roomsize: string) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.SET_GLOBAL_ROOM_SIZE}`;
    const res = await Http.post(url, { roomsize });

    return res;
  };

  const getRoomLimit = async () => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.GET_ROOM_LIMIT}`;
    const res = await Http.get(url);

    return res;
  };

  const setRoomLimit = async (limit: string) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.SET_ROOM_LIMIT}`;
    const res = await Http.post(url, { roomcreatelimit: limit });

    return res;
  };

  const getIntervalLimit = async () => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.GET_INTERVAL_LIMIT}`;
    const res = await Http.get(url);

    return res;
  };

  const setIntervalLimit = async (limit: string) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.SET_INTERVAL_LIMIT}`;
    const res = await Http.post(url, { trialHours: limit });

    return res;
  };

  return { getGlobalRoomSize, setGlobalRoomSize, getRoomLimit, setRoomLimit, getIntervalLimit, setIntervalLimit };
};

export default useSettings;
