import React, { useState } from 'react';
import './RoomsItem.scss';
import { Room } from '../../types/rooms';
import { Link } from 'react-router-dom';
import getLocalDate from '../../utilsCommon/UtilsCommon';
import SVG_ICONS from '../../constants/SVG';
import ROUTES from '../../constants/routerConstants';
import Modal from '../_common/Modal/Modal';
import Checkbox from '../_common/Checkbox/Checkbox';
import useRooms from '../../hooks/useRooms';
import { USER_TOKEN_NAMESPACE } from '../../constants/api';
interface Props {
  item: Room;
  type?: string;
}

function RoomsItem({ item, type }: Props) {
  const { switchFeatureRoom, getFeaturedRooms, switchTrendRoom, getTrendingRooms } = useRooms();
  const [isModal, setIsModal] = useState(false);
  const [isFeature, setIsFeature] = useState(true);
  const [isTrend, setIsTrend] = useState(true);

  const handleFeatureClick = async () => {
    await switchFeatureRoom(item.ROOM_ID);
    getFeaturedRooms();
    setIsModal(false);
  };

  const handleTrendClick = async () => {
    await switchTrendRoom(item.ROOM_ID);
    getTrendingRooms();
    setIsModal(false);
  };

  const token = window.localStorage.getItem(USER_TOKEN_NAMESPACE);

  const renderModalContent = () => {
    switch (type) {
      case 'featured':
        return (
          <>
            <h2>Confirm room unfeature?</h2>
            <Checkbox
              label={'Feature Room'}
              btnText={'Accept'}
              handleClick={handleFeatureClick}
              handleChange={() => setIsFeature(!isFeature)}
              isDisabled={false}
              isChecked={isFeature}
              name={'feaure'}
            />
          </>
        );
      case 'trend':
        return (
          <>
            <h2>Confirm room untrend?</h2>
            <Checkbox
              label={'Trend Room'}
              btnText={'Accept'}
              handleClick={handleTrendClick}
              handleChange={() => setIsTrend(!isTrend)}
              isDisabled={false}
              isChecked={isTrend}
              name={'trend'}
            />
          </>
        );
      default:
        return;
    }
  };

  const renderItem = () => {
    switch (type) {
      case 'featured':
        return (
          <>
            <p className={'room-item_name'}>{item.ROOM_NAME}</p>
            <p className={'room-item_description'}>{item.ROOM_DESCRIPTION}</p>
            <p className={'room-item_owner'}>{item.WALLETID}</p>
            <a
              className={'room-item_link'}
              href={`https://metaverse.bluemoon.io/${item.ROOM_ID}?entry=${token}`}
              target="_blank"
              rel="noreferrer"
            >
              Open
            </a>
            <p className={'room-item_featuredDate'}>{item.DATEFEATURED && getLocalDate(item.DATEFEATURED as Date)}</p>
            <button className={'room-item_unfeature'} onClick={() => setIsModal(true)}>
              Unfeature
            </button>
            <Link to={`${ROUTES.ROOMS}/${item.ROOM_ID}`}>
              <img src={SVG_ICONS.ARROW_RIGHT} alt="open room info" />
            </Link>
          </>
        );
      case 'trend':
        return (
          <>
            <p className={'room-item_name'}>{item.ROOM_NAME}</p>
            <p className={'room-item_description'}>{item.ROOM_DESCRIPTION}</p>
            <p className={'room-item_owner'}>{item.WALLETID}</p>
            <a
              className={'room-item_link'}
              href={`https://metaverse.bluemoon.io/${item.ROOM_ID}?entry=${token}`}
              target="_blank"
              rel="noreferrer"
            >
              Open
            </a>
            <p className={'room-item_featuredDate'}>{item.DATE_TRENDED && getLocalDate(item.DATE_TRENDED as Date)}</p>
            <button className={'room-item_unfeature'} onClick={() => setIsModal(true)}>
              Untrend
            </button>
            <Link to={`${ROUTES.ROOMS}/${item.ROOM_ID}`}>
              <img src={SVG_ICONS.ARROW_RIGHT} alt="open room info" />
            </Link>
          </>
        );
      default:
        return (
          <>
            <p className={'room-item_name'}>{item.ROOM_NAME}</p>
            <p className={'room-item_description'}>{item.ROOM_DESCRIPTION}</p>
            <p className={'room-item_owner'}>{item.WALLETID || '-'}</p>
            <a
              className={'room-item_link'}
              href={`https://metaverse.bluemoon.io/${item.ROOM_ID}?entry=${token}`}
              target="_blank"
              rel="noreferrer"
            >
              Open
            </a>
            <p className={'room-item_createdDate'}>{item.CREATED_DATE && getLocalDate(item.CREATED_DATE as Date)}</p>
            <p className={'room-item_lastUpdate'}>{item.LAST_UPDATED && getLocalDate(item.LAST_UPDATED as Date)}</p>
            <Link to={`${ROUTES.ROOMS}/${item.ROOM_ID}`}>
              <img src={SVG_ICONS.ARROW_RIGHT} alt="open room info" />
            </Link>
          </>
        );
    }
  };

  return (
    <div className={'room-item'}>
      <Modal open={isModal} onClose={() => setIsModal(false)}>
        <div className={'rooms-item__modal'}>{renderModalContent()}</div>
      </Modal>
      {renderItem()}
    </div>
  );
}

export default RoomsItem;
