import React from 'react';
import './ConfirmReset.scss';
import { Field, Form, Formik } from 'formik';
import SVG_ICONS from '../../constants/SVG';
import Button from '../../components/_common/Button/Button';
import confirmPassword from '../../constants/YupShemas';
import useAuth from '../../hooks/useAuth';
import { useLocation } from 'react-router-dom';

function ConfirmReset() {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get('auth');
  const { confirmResetPassword } = useAuth();

  return (
    <div className={'confirm_reset_wrapper'}>
      <Formik
        validationSchema={confirmPassword}
        initialValues={{ password: '', confirm: '' }}
        onSubmit={(values) => {
          console.log(values, 'values');
          if (token) {
            confirmResetPassword(values.password, token);
          }
        }}
        validateOnMount
      >
        {({ values, handleChange, errors }) => {
          console.log(errors, 'err');

          return (
            <Form className={'confirm_reset-form'}>
              <img src={SVG_ICONS.MAIN_LOGO} alt="main logo" />
              <h2 className={'confirm_reset-form_title'}>Set Account Password</h2>
              <div className={'progress'}>
                <p>Your Password Must:</p>
                <label className="container">
                  <input readOnly disabled type="checkbox" />
                  <span className="checkmark" />
                  Use a least 8 characters
                </label>
                <label className="container">
                  <input readOnly type="checkbox" />
                  <span className="checkmark" />
                  Use a capital letter
                </label>
                <label className="container">
                  <input readOnly type="checkbox" />
                  <span className="checkmark" />
                  Use a number
                </label>
                <label className="container">
                  <input readOnly type="checkbox" />
                  <span className="checkmark" />
                  Use a special character. 1 % & @ # 4 *
                </label>
              </div>
              <p className={'field_title'}>password</p>
              <Field
                placeholder={'Enter your password'}
                name={'password'}
                className={'form_input'}
                onChange={handleChange('password')}
                type={'password'}
                value={values.password}
              />
              <p className={'field_title'}>Confirm Password</p>

              <Field
                placeholder={'Enter your password'}
                name={'confirm'}
                className={'form_input'}
                onChange={handleChange('confirm')}
                type={'password'}
                value={values.confirm}
              />
              <Button title={'SET PASSWORD'} type={'submit'} className={'login_button'} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ConfirmReset;
