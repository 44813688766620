import { USER_TOKEN_NAMESPACE } from '../constants/api';
import LocalStorage from './localStorage';
import axios from 'axios';

/* eslint-disable */

export interface ExtraHeaders {
  'Access-Control-Allow-Origin'?: string;
  Authorization?: string;
  'Content-Type'?: string;
}

export const authorizationHeader = {
  Authorization: `Bearer ${LocalStorage.get(USER_TOKEN_NAMESPACE)}`,
};

export const Http = {
  getHeaders: async (extraHeaders?: ExtraHeaders) => {
    const userToken = await LocalStorage.get(USER_TOKEN_NAMESPACE);
    const defaultHeaders = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    };

    return extraHeaders ? { ...defaultHeaders, ...extraHeaders } : defaultHeaders;
  },

  get: async (url: string, extraHeaders?: ExtraHeaders) => {
    try {
      const headers = await Http.getHeaders(extraHeaders);
      const response = await fetch(url, {
        method: 'GET',
        headers,
      });

      const responseData = await response.json();

      if (responseData.error || responseData.statusCode) {
        return await Promise.reject(responseData);
      }

      return responseData;
    } catch (e) {
      console.error(e);
    }
  },

  post: async (url: string, body: any, extraHeaders?: ExtraHeaders) => {
    try {
      const headers = await Http.getHeaders(extraHeaders);
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers,
      });
      const responseData = await response.json();

      if (!response.ok) {
        return await Promise.reject(responseData);
      }

      return responseData;
    } catch (e) {
      if ((e as Error).message === 'Failed to fetch') {
        console.log('Failed to fetch');
        return [];
      }
      console.error(e);
      return e;
    }
  },

  delete: async (url: string, extraHeaders?: ExtraHeaders) => {
    try {
      const headers = await Http.getHeaders(extraHeaders);

      await fetch(url, {
        method: 'DELETE',
        headers,
      });
    } catch (e) {
      console.error(e);
    }
  },

  patch: async (url: string, body: any, extraHeaders?: ExtraHeaders) => {
    try {
      const headers = await Http.getHeaders(extraHeaders);
      const response = await fetch(url, {
        method: 'PATCH',
        body: JSON.stringify(body),
        headers,
      });

      const responseData = await response.json();

      if (responseData.detail || responseData.statusCode) {
        return await Promise.reject(responseData);
      }

      return responseData;
    } catch (e) {
      console.error(e);
    }
  },

  postFile: async (url: string, body: any, extraHeaders?: ExtraHeaders) => {
    try {
      const headers = await Http.getHeaders(extraHeaders);

      await axios.post(url, body, { headers });
    } catch (e) {
      console.error(e);
    }
  },

  patchFile: async (url: string, body: any, extraHeaders?: ExtraHeaders): Promise<any> => {
    try {
      const headers = await Http.getHeaders(extraHeaders);

      return await axios.patch(url, body, { headers });
    } catch (e) {
      console.error(e);
    }
  },
};
