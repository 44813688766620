import React from 'react';
import './Button.scss';

interface Props {
  title?: string;
  clickHandler?: (e?: React.MouseEvent<HTMLElement>) => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
  className?: string;
  name?: string;
  children?: JSX.Element;
}

function Button({ children, title, clickHandler, disabled, type, className, name }: Props) {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={clickHandler}
      className={className ? className : 'add-btn'}
      name={name}
    >
      {children || title}
    </button>
  );
}

export default Button;
