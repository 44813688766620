import React, { useState } from 'react';
import './PagesHeader.scss';
import SVG_ICONS from '../../constants/SVG';
import { useLocation } from 'react-router-dom';
import ROUTES from '../../constants/routerConstants';
import useUsers from '../../hooks/useUsers';
import { Field, Form, Formik } from 'formik';
import useRooms from '../../hooks/useRooms';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';

type PagesHeaderProps = {
  btnText?: string;
  handleClick?: () => void;
};

function PagesHeader({ btnText, handleClick }: PagesHeaderProps) {
  const { pathname } = useLocation();
  const { getUsersList, findUser, getProAccessRequests } = useUsers();
  const { findRoom, getRooms } = useRooms();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const usersData = useSelector((state: ApplicationState) => state.users.dataList);
  const roomsData = useSelector((state: ApplicationState) => state.rooms.dataList);
  const { proAccess } = useSelector((state: ApplicationState) => state.users);

  const nextPage = () => {
    switch (pathname) {
      case ROUTES.USERS: {
        if (usersData.length !== 0) {
          setCurrentPage(currentPage + 1);
          getUsersList({ pagenum: currentPage + 1 });
        }

        break;
      }

      case ROUTES.ROOMS: {
        if (roomsData.length !== 0) {
          setCurrentPage(currentPage + 1);
          getRooms({ pagenum: currentPage + 1 });
        }

        break;
      }

      case ROUTES.META_PRO_EDITOR: {
        if (proAccess.length !== 0) {
          setCurrentPage(currentPage + 1);
          getProAccessRequests(currentPage + 1);
        }

        break;
      }
    }
  };

  const prevPage = () => {
    switch (pathname) {
      case ROUTES.USERS: {
        setCurrentPage(currentPage - 1);
        getUsersList({ pagenum: currentPage - 1 });
        break;
      }

      case ROUTES.ROOMS: {
        setCurrentPage(currentPage - 1);
        getRooms({ pagenum: currentPage - 1 });

        break;
      }

      case ROUTES.META_PRO_EDITOR: {
        setCurrentPage(currentPage - 1);
        getProAccessRequests(currentPage - 1);
        break;
      }
    }
  };

  const renderSearchField = () => {
    return (
      <Formik
        initialValues={{ search: '' }}
        onSubmit={(values) => {
          switch (pathname) {
            case ROUTES.USERS:
              {
                findUser({
                  pagenum: 1,
                  param: values.search,
                });
              }

              return;
            case ROUTES.ROOMS: {
              findRoom({
                pagenum: 1,
                param: values.search,
              });

              return;
            }

            case ROUTES.META_PRO_EDITOR: {
              getProAccessRequests(1, values.search);

              return;
            }
          }
        }}
      >
        {({ values, handleChange }) => {
          return (
            <Form>
              <Field
                className={'search_field'}
                name={'search'}
                value={values.search}
                onChange={handleChange('search')}
                placeholder={'Type in to Search'}
              />
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <div className={'pages-header'}>
      {renderSearchField()}
      <div className={'pages-header__actions'}>
        {btnText && (
          <div className={'pages-header__btn-container'}>
            <button className={'pages-header__btn'} type="button" onClick={handleClick}>
              <img className={'pages-header__btn-icon'} src={SVG_ICONS.CIRCLE_PLUS} alt="button icon" />
              <span className={'pages-header__btn-text'}>{btnText}</span>
            </button>
          </div>
        )}
        <div className={'pagination-buttons'}>
          <div onClick={currentPage === 1 ? () => {} : () => prevPage()} className={'arrow_wrapper'}>
            <img className={'arrow'} src={SVG_ICONS.CHEVRON_LEFT} alt="arrow left" />
          </div>
          <div onClick={() => nextPage()} className={'arrow_wrapper'}>
            <img className={'arrow'} src={SVG_ICONS.CHEVRON_RIGHT} alt="arrow right" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PagesHeader;
