import { API_BASE_URL, API_ENDPOINTS } from '../constants/api';
import { Http } from '../services/http';
import { useDispatch } from 'react-redux';
import {
  actionGetAccessPassSearch24,
  actionGetAccessPassSearchLegend,
  actionGetAccessPassSearchMonth,
  actionGetAccessPassSearchYear,
  actionGetAdminMostClickedNft,
  actionGetAdminMostRevenueRooms,
  actionGetAdminNftClicks,
  actionGetAdminNftSoldCount,
  actionGetAdminNftSoldSum,
  actionGetAdminNftSoldSumPerDay,
  actionGetAdminRoomVisits,
  actionGetDailyUniqueUsers,
  actionGetFreeTrailUsersSearch,
  actionGetMonthlyUniqueUsers,
  actionGetNumberOfPlacedNFT,
  actionGetNumberOfRooms,
  actionGetNumberOfSoldNft,
  actionGetNumberOfUsers,
  actionGetTotalAccessPassSearch,
} from '../store/actions/chartsActions';
import {
  DateRange,
  DateRangeNew,
  DateSoldNfts,
  IRoomVisits,
  INftSoldSum,
  INftSoldCount,
  INftClicks,
} from '../types/charts';

const useCharts = () => {
  const dispatch = useDispatch();
  const getNumberOfUsers = async (data: DateRange) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.GET_NUMBER_OF_USERS}`;
    const res = await Http.post(url, data);

    dispatch(actionGetNumberOfUsers(res));

    return res;
  };

  const getNumberOfRooms = async (data: DateRange) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.GET_NUMBER_OF_ROOMS}`;
    const res = await Http.post(url, data);

    dispatch(actionGetNumberOfRooms(res));

    return res;
  };

  const getNumberOfSoldNft = async (data: DateSoldNfts) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.NFTS_SOLD}`;
    const res = await Http.post(url, data);

    dispatch(actionGetNumberOfSoldNft(res));

    return res;
  };

  const getNumberOfPlacedNFT = async (data: DateRange) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.GET_NUMBER_OF_PLACED_NFT}`;
    const res = await Http.post(url, data);

    dispatch(actionGetNumberOfPlacedNFT(res));

    return res;
  };

  const getAccessPassSearch24 = async (data: DateRangeNew) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.ACCESS_PASS_SEARCH_24}`;
    const res = await Http.post(url, data);

    dispatch(actionGetAccessPassSearch24(res));

    return res;
  };

  const getAccessPassSearchMonth = async (data: DateRangeNew) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.ACCESS_PASS_SEARCH_MONTH}`;
    const res = await Http.post(url, data);

    dispatch(actionGetAccessPassSearchMonth(res));

    return res;
  };

  const getAccessPassSearchYear = async (data: DateRangeNew) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.ACCESS_PASS_SEARCH_YEAR}`;
    const res = await Http.post(url, data);

    dispatch(actionGetAccessPassSearchYear(res));

    return res;
  };

  const getTotalAccessPassSearch = async (data: DateRangeNew) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.TOTAL_ACCESS_PASS_SEARCH}`;
    const res = await Http.post(url, data);

    dispatch(actionGetTotalAccessPassSearch(res));

    return res;
  };

  const getFreeTrailUsersSearch = async (data: DateRangeNew) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.FREE_TRAIL_USERS_SEARCH}`;
    const res = await Http.post(url, data);

    dispatch(actionGetFreeTrailUsersSearch(res));

    return res;
  };

  const getDailyUniqueUsers = async (data: DateRangeNew) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.DAILY_UNIQUE_USERS}`;
    const res = await Http.post(url, data);

    dispatch(actionGetDailyUniqueUsers(res));

    return res;
  };

  const getMonthlyUniqueUsers = async (data: DateRangeNew) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.MONTHLY_UNIQUE_USERS}`;
    const res = await Http.post(url, data);

    dispatch(actionGetMonthlyUniqueUsers(res));

    return res;
  };

  const getAdminRoomVisits = async (data: IRoomVisits) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.ADMIN_ROOM_VISITS}`;
    const res = await Http.post(url, data);

    dispatch(actionGetAdminRoomVisits(res));

    return res;
  };

  const getAdminNftSoldSum = async (data: INftSoldSum) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.ADMIN_NFT_SOLD_SUM}`;
    const res = await Http.post(url, data);

    dispatch(actionGetAdminNftSoldSum(res));

    return res;
  };

  const getAdminNftSoldCount = async (data: INftSoldCount) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.ADMIN_NFT_SOLD_COUNT}`;
    const res = await Http.post(url, data);

    dispatch(actionGetAdminNftSoldCount(res));

    return res;
  };

  const getAdminNftClicks = async (data: INftClicks) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.ADMIN_NFT_CLICKS}`;
    const res = await Http.post(url, data);

    dispatch(actionGetAdminNftClicks(res));

    return res;
  };

  const getAdminMostRevenueRooms = async (data: DateRangeNew) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.ADMIN_MOST_REVENUE_ROOMS}`;
    const res = await Http.post(url, data);

    dispatch(actionGetAdminMostRevenueRooms(res));

    return res;
  };

  const getAdminMostClickedNft = async (data: DateRangeNew) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.ADMIN_MOST_CLICKED_NFT}`;
    const res = await Http.post(url, data);

    dispatch(actionGetAdminMostClickedNft(res));

    return res;
  };

  const getAccessPassSearchLegend = async (data: DateRangeNew) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.ACCESS_PASS_SEARCH_LEGEND}`;
    const res = await Http.post(url, data);

    dispatch(actionGetAccessPassSearchLegend(res));

    return res;
  };

  const getAdminNftSoldSumPerDay = async (data: DateRangeNew) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.ADMIN_NFT_SOLD_SUM_PER_DAY}`;
    const res = await Http.post(url, data);

    dispatch(actionGetAdminNftSoldSumPerDay(res));

    return res;
  };

  const adminTotalRevenue = async (data: DateRangeNew) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.ADMIN_TOTAL_REVENUE}`;
    const res = await Http.post(url, data);

    return res;
  };

  const getNonLoggedInUsers = async () => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.GET_NON_LOGGED_IN_USERS}`;
    const res = await Http.get(url);

    return res;
  };

  const getLiveUsers = async () => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.GET_LIVE_USERS}`;
    const res = await Http.get(url);

    return res;
  };

  return {
    getNumberOfUsers,
    getNumberOfRooms,
    getNumberOfSoldNft,
    getNumberOfPlacedNFT,
    getNonLoggedInUsers,
    getLiveUsers,
    getAccessPassSearch24,
    getAccessPassSearchMonth,
    getAccessPassSearchYear,
    getTotalAccessPassSearch,
    getFreeTrailUsersSearch,
    getDailyUniqueUsers,
    getMonthlyUniqueUsers,
    getAdminRoomVisits,
    getAdminNftSoldSum,
    getAdminNftSoldCount,
    getAdminNftClicks,
    getAdminMostRevenueRooms,
    getAdminMostClickedNft,
    adminTotalRevenue,
    getAccessPassSearchLegend,
    getAdminNftSoldSumPerDay,
  };
};

export default useCharts;
