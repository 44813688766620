import { ActionInterface } from '../../types/common';
import { roomsReducerDefaultState } from '../../constants/defaultData';
import ROOMS_ACTION_TYPES from '../actionTypes/RoomsActionTypes';
import { NftByRoom, Room, RoomSizeInfoItem } from '../../types/rooms';

export interface RoomsReducer {
  dataList: Room[];
  specificRoom: Room;
  specificRoomSize: RoomSizeInfoItem[];
  currentRoomNFTs: NftByRoom[];
}

function roomsReducer(state: RoomsReducer = roomsReducerDefaultState, { data, type }: ActionInterface) {
  switch (type) {
    case ROOMS_ACTION_TYPES.GET_ROOMS: {
      return { ...state, dataList: data };
    }

    case ROOMS_ACTION_TYPES.FIND_ROOM: {
      return { ...state, dataList: data };
    }

    case ROOMS_ACTION_TYPES.GET_SPECIFIC_ROOM: {
      return { ...state, specificRoom: data[0] };
    }

    case ROOMS_ACTION_TYPES.GET_ROOM_NFT_DETAILS: {
      return { ...state, currentRoomNFTs: data };
    }

    case ROOMS_ACTION_TYPES.GET_ROOM_SIZE: {
      return { ...state, specificRoomSize: data };
    }
  }

  return state;
}

export default roomsReducer;
