export const USER_TOKEN_NAMESPACE = 'token-name';
// export const API_BASE_URL = 'https://kqwoc75v73.execute-api.eu-west-2.amazonaws.com/latest';
export const API_BASE_URL = 'https://vision.bluemoon.land';
// export const API_BASE_URL = 'http://localhost:2000';
// export const API_BASE_URL = 'https://bluemoon-metaverse.nw.r.appspot.com';
// export const API_GRAPHIQL = 'https://spaces.bluemoon.io/api/v2_alpha/graphiql';
export const API_GRAPHIQL = 'https://bluemoon.land/api/v2_alpha/graphiql';
export const API_ENDPOINTS = {
  GET_USERS: '/adminuserspull',
  LOGIN: '/adminlogin',
  GET_ROOMS: '/userroomssceneslist',
  GET_FEATURED_ROOMS: '/getfeaturedrooms',
  INVITE_TOKEN_LIST: '/invitetokenslist',
  CURRENT_EMPLOYEE: '/admindashboarduserspull',
  CANCEL_INVITE: '/revokeinvitetoken',
  SEND_INVITE: '/sendreglink',
  REGISTRATION: '/adminregister',
  GET_USER_BY_SEARCH: '/userssearch',
  GET_ROOMS_BY_USER_ID: '/getuserspecificrooms',
  GET_NFT_BY_USER_ROOM: '/getasset',
  SEND_RESET_PASSWORD_LINK: '/adminforgotpasswordinit',
  CONFIRM_RESET_PASSWORD: '/adminforgotpasswordconfirm',
  GET_NUMBER_OF_USERS: '/usersdatesearch',
  GET_NUMBER_OF_ROOMS: '/roomsdatesearch',
  GET_NFT_SOLD: '/transactionsdatesearch',
  GET_NUMBER_OF_PLACED_NFT: '/assetplaceddatesearch',
  FIND_ROOM: '/adminroomssearch',
  GET_NFT_BY_ROOM: '/roomassetslist',
  GET_EVENT_ENTER: '/evententer',
  USER_EVENT_RESET: '/usereventreset',
  USER_REGISTER: '/register',
  GET_ROOM_LIMIT: '/getroomcreatelimit',
  SET_ROOM_LIMIT: '/setcreateroomlimit',
  TOGGLE_UNLIMITED_USER_ROOMS: '/toggleunlimiteduserrooms',
  TOGGLE_MESSAGE_BAN: '/togglemessagebanuser',
  TOGGLE_BAN_USER: '/togglebanuser',
  SWITCH_SUSPEND_ROOM: '/suspendswitchroom',
  SWITCH_FEATURE_ROOM: '/featureroom',
  EDIT_ROOM_NAME: '/editroomname',
  EDIT_ROOM_DESCRIPTION: '/editroomdescription',
  TOGGLE_UNLIMITED_USER_SPECIFIC_ROOM: '/toggleunlimiteduserspecificroom',
  GET_PRO_ACCESS_REQUEST: '/getproaccessrequests',
  GET_SINGLE_PRO_ACCESS: '/getsingleproaccessrequest',
  UPDATE_ROOM_SIZE: '/updatesingleroomsize',
  ADMIN_CREATE_ROOM: '/admincreateroom',
  GET_GLOBAL_ROOM_SIZE: '/getglobalroomsize',
  SET_GLOBAL_ROOM_SIZE: '/updateglobalroomsize',
  GET_WALLETID_BY_UID: '/getwalletidbyuid',
  NFTS_SOLD: '/nftssold',
  GET_INTERVAL_LIMIT: '/gettrialhours',
  SET_INTERVAL_LIMIT: '/settrialhours',
  ADMIN_ASSIGN_SCENE: '/adminassignscene',
  GET_TRENDING_ROOMS: '/gettrendingrooms',
  SWITCH_TRENDING_ROOM: '/settrendingroom',
  GET_NON_LOGGED_IN_USERS: '/getnonloggedinusers',
  GET_LIVE_USERS: '/getliveusers',
  ACCESS_PASS_SEARCH_24: '/accesspasssearch24hours',
  ACCESS_PASS_SEARCH_MONTH: '/accesspasssearchmonth',
  ACCESS_PASS_SEARCH_YEAR: '/accesspasssearchyear',
  TOTAL_ACCESS_PASS_SEARCH: '/totalaccesspasssearch',
  FREE_TRAIL_USERS_SEARCH: '/freetrailuserssearch',
  DAILY_UNIQUE_USERS: '/dailyuniqueusers',
  MONTHLY_UNIQUE_USERS: '/monthlyuniqueusers',
  ADMIN_ROOM_VISITS: '/adminroomvisits',
  ADMIN_NFT_SOLD_SUM: '/adminnftsoldsum',
  ADMIN_NFT_SOLD_COUNT: '/adminnftsoldcount',
  ADMIN_NFT_CLICKS: '/adminnftclicks',
  ADMIN_MOST_REVENUE_ROOMS: '/adminmostrevenuerooms',
  ADMIN_MOST_CLICKED_NFT: '/adminmostclickednft',
  ADMIN_TOTAL_REVENUE: '/admintotalrevenue',
  ADMIN_LIST_NFT_SALES: '/adminlistnftsales',
  ACCESS_PASS_SEARCH_LEGEND: '/accesspasssearchlegend',
  ADMIN_NFT_SOLD_SUM_PER_DAY: '/adminnftsoldsumperday',
  ADMIN_GET_ROOM_PURCHASES: '/admingetroompurchases',
};
