import React, { ChangeEvent, useEffect, useState } from 'react';

import './SettingsPage.scss';
import useSettings from '../../hooks/useSettings';

const SettingsPage = () => {
  const [roomSettings, setRoomSettings] = useState({ roomSize: '', roomLimit: '', interval: '' });
  const [isSaved, setIsSaved] = useState({ roomSize: false, roomLimit: false, interval: false });
  const { getGlobalRoomSize, setGlobalRoomSize, getRoomLimit, setRoomLimit, getIntervalLimit, setIntervalLimit } =
    useSettings();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isNumber = !isNaN(Number(e.target.value));

    if (isNumber) {
      setRoomSettings((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    }
  };

  const handleRoomSizeClick = async () => {
    const res = await setGlobalRoomSize(roomSettings.roomSize);

    if (res?.success) {
      setIsSaved((prevState) => ({ ...prevState, roomSize: true }));
    }
  };

  const handleRoomLimitClick = async () => {
    const res = await setRoomLimit(roomSettings.roomLimit);

    if (res?.success) {
      setIsSaved((prevState) => ({ ...prevState, roomLimit: true }));
    }
  };

  const handleIntervalClick = async () => {
    const res = await setIntervalLimit(roomSettings.interval);

    if (res?.success) {
      setIsSaved((prevState) => ({ ...prevState, interval: true }));
    }
  };

  useEffect(() => {
    getGlobalRoomSize().then(({ globalroomsize }) => {
      setRoomSettings((prevState) => ({ ...prevState, roomSize: globalroomsize }));
    });
    getRoomLimit().then(({ data }) => setRoomSettings((prevState) => ({ ...prevState, roomLimit: data })));
    getIntervalLimit().then(({ data }) => setRoomSettings((prevState) => ({ ...prevState, interval: data })));
  }, []);

  return (
    <section className={'settings'}>
      <div className={'settings__inner'}>
        <div className={'settings__item'}>
          <h2 className={'settings__item-label'}>
            Room Size {isSaved.roomSize && <span className={'settings__item-save'}>(saved)</span>}
          </h2>
          <input
            type={'text'}
            className={'settings__item-input'}
            placeholder={'Enter Room Size'}
            name={'roomSize'}
            value={roomSettings.roomSize}
            onChange={handleChange}
          />
          <button type={'button'} className={'settings__item-button'} onClick={handleRoomSizeClick}>
            Set
          </button>
        </div>
        <div className={'settings__item'}>
          <h2 className={'settings__item-label'}>
            Room Creating Limit {isSaved.roomLimit && <span className={'settings__item-save'}>(saved)</span>}
          </h2>
          <input
            type={'text'}
            className={'settings__item-input'}
            placeholder={'Enter limit e.g. 2'}
            name={'roomLimit'}
            value={roomSettings.roomLimit}
            onChange={handleChange}
          />
          <button type={'button'} className={'settings__item-button'} onClick={handleRoomLimitClick}>
            Set
          </button>
        </div>
        <div className={'settings__item'}>
          <h2 className={'settings__item-label'}>
            Sign Up Time Interval {isSaved.interval && <span className={'settings__item-save'}>(saved)</span>}
          </h2>
          <input
            type={'text'}
            className={'settings__item-input'}
            placeholder={'Enter interval e.g. 2'}
            name={'interval'}
            value={roomSettings.interval}
            onChange={handleChange}
          />
          <button type={'button'} className={'settings__item-button'} onClick={handleIntervalClick}>
            Set
          </button>
        </div>
      </div>
    </section>
  );
};

export default SettingsPage;
