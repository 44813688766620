import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/LoginPage/Login';
import ROUTES from './constants/routerConstants';
import LayOut from './components/Layout/LayOut';
import RequireAuth from './components/_common/RequaredAuth/RequaredAuth';
import UsersPage from './pages/UsersPage/UsersPage';
import ResetPassword from './pages/ResetPasswordPage/ResetPassword';
import OverViewPage from './pages/OverViewPage/OverViewPage';
import EmployeePage from './pages/EmployeePage/EmployeePage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import SpecialUser from './pages/SpecialUser/SpecialUser';
import SpecificRoom from './pages/SpecialRoom/SpecificRoom';
import ConfirmReset from './pages/ConfirmResetPassword/ConfirmReset';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import RoomsPage from './pages/RoomsPage/RoomsPage';
import MetaProEditorPage from './pages/MetaProEditorPage/MetaProEditorPage';
import EventsPage from './pages/EventsPage/EventsPage';
import AnalyticsPage from './pages/AnalyticsPage/AnalyticsPage';
// import CompaniesPage from './pages/CompaniesPage/CompaniesPage';
// import CompanyDetailPage from './pages/CompanyDetailPage/CompanyDetailPage';

function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path={ROUTES.LOGIN} element={<Login />} />
        <Route path={`${ROUTES.REGISTER}`} element={<RegisterPage />} />
        <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
        <Route path={`${ROUTES.CONFIRM_RESET}`} element={<ConfirmReset />} />
        <Route path={'/'} element={<LayOut />}>
          <Route
            path={ROUTES.OVERVIEW}
            element={
              <RequireAuth>
                <OverViewPage />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.EVENTS}
            element={
              <RequireAuth>
                <EventsPage />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.ANALYTICS}
            element={
              <RequireAuth>
                <AnalyticsPage />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.USERS}
            element={
              <RequireAuth>
                <UsersPage />
              </RequireAuth>
            }
          />
          <Route
            path={`${ROUTES.USERS}/:wallet/:id/:active`}
            element={
              <RequireAuth>
                <SpecialUser />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.ROOMS}
            element={
              <RequireAuth>
                <RoomsPage />
              </RequireAuth>
            }
          />
          <Route
            path={`${ROUTES.ROOMS}/:name`}
            element={
              <RequireAuth>
                <SpecificRoom />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.EMPLOYEE_MANAGEMENT}
            element={
              <RequireAuth>
                <EmployeePage />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.META_PRO_EDITOR}
            element={
              <RequireAuth>
                <MetaProEditorPage />
              </RequireAuth>
            }
          />
          {/*<Route*/}
          {/*  path={ROUTES.COMPANIES}*/}
          {/*  element={*/}
          {/*    <RequireAuth>*/}
          {/*      <CompaniesPage />*/}
          {/*    </RequireAuth>*/}
          {/*  }*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  path={`${ROUTES.COMPANIES}/:name`}*/}
          {/*  element={*/}
          {/*    <RequireAuth>*/}
          {/*      <CompanyDetailPage />*/}
          {/*    </RequireAuth>*/}
          {/*  }*/}
          {/*/>*/}
          <Route
            path={ROUTES.SETTINGS}
            element={
              <RequireAuth>
                <SettingsPage />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="*" element={<p>not found</p>} />
      </Routes>
    </Router>
  );
}

export default AppRouter;
