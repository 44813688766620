import React from 'react';
import './LineChart.scss';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface Props {
  labels: string[];
  data: number[] | string[];
  color: string;
  title: string;
  data2?: number[] | string[];
}

interface IDataSet {
  border?: boolean;
  borderColor?: string;
  backgroundColor: string;
  fill: boolean;
  label: string;
  data: number[] | string[];
}

function LineChart({ labels, data, color, title, data2 }: Props) {
  const dataset: IDataSet[] = [
    {
      border: true,
      backgroundColor: '#000',
      fill: true,
      label: '',
      data: data,
    },
  ];

  if (data2) {
    dataset.push({
      borderColor: 'green',
      backgroundColor: 'green',
      fill: true,
      label: 'Data 2',
      data: data2,
    });
  }

  const chartData = {
    labels: labels,
    datasets: dataset,
  };
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    events: [],
    cornerRadius: 6,
    scales: {
      y: {
        title: {
          display: true,
          font: {
            size: 12,
          },
        },
        ticks: {
          display: true,
          precision: 0,
        },
        grid: {
          display: true,
          drawBorder: false,
          drawOnChartArea: true,
          drawTicks: false,
          borderDash: [4],
        },
      },
      x: {
        title: {
          display: true,
          font: {
            size: 12,
          },
        },
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: true,
          drawTicks: false,
          borderDash: [4],
        },
        ticks: {},
      },
      // yAxes: {
      //   override: true,
      //   display: false,
      //   scaleLabel: {
      //     display: true,
      //   },
      //   ticks: {
      //     maxTicksLimit: 3,
      //     override: true,
      //     beginAtZero: true,
      //   },
      // },
      // xAxes: {
      //   display: false,
      //   scaleLabel: {
      //     display: false,
      //   },
      //   ticks: {
      //     display: false,
      //   },
      // },
    },

    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className={'line'}>
      <div className={'line_header'}>
        <span style={{ backgroundColor: color }} />
        <h2>{title}</h2>
      </div>
      <Line data={chartData} options={options} height={320} />
    </div>
  );
}

export default LineChart;
