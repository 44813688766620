const ROUTES = {
  LOGIN: '/',
  RESET_PASSWORD: '/reset_password',
  OVERVIEW: '/overview',
  EVENTS: '/events',
  ANALYTICS: '/analytics',
  USERS: '/users',
  ROOMS: '/rooms',
  EMPLOYEE_MANAGEMENT: '/employee_management',
  REGISTER: '/register',
  CONFIRM_RESET: '/passwordconfirm',
  SETTINGS: '/settings',
  COMPANIES: '/companies',
  META_PRO_EDITOR: '/meta_pro_editor',
};

export default ROUTES;
