import React, { useEffect, useState } from 'react';
import './MetaProEditor.scss';
import useUsers from '../../hooks/useUsers';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import PagesHeader from '../../components/PagesHeader/PagesHeader';
import Tabs from '../../components/Tabs/Tabs';
import { metaProTabs } from '../../constants/defaultData';
import SVG_ICONS from '../../constants/SVG';
import { Link } from 'react-router-dom';
import ROUTES from '../../constants/routerConstants';
// import Button from '../../components/_common/Button/Button';
// import validate from '../../constants/validate';
// import { RegisterUser } from '../../types/users';

function MetaProEditorPage() {
  const { getProAccessRequests } = useUsers();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const { proAccess } = useSelector((state: ApplicationState) => state.users);

  const renderMetaProList = () =>
    proAccess &&
    proAccess.map(({ EMAILADDRESS, UID, WALLETID }, index) => {
      return (
        <li key={index} className={'meta-pro_page-list_item'}>
          <p className={'email'}>{EMAILADDRESS}</p>
          <Link to={`${ROUTES.USERS}/${WALLETID}/${UID}/1`}>
            <img src={SVG_ICONS.ARROW_RIGHT} alt="open user info" />
          </Link>
        </li>
      );
    });

  useEffect(() => {
    getProAccessRequests(1);
  }, []);

  return (
    <div className={'meta-pro_page'}>
      <Tabs tabs={metaProTabs} changeTabHandler={setCurrentTab} currentTab={currentTab} />
      <PagesHeader />
      <div className={'meta-pro_page_list_wrapper'}>
        <div className={'meta-pro_page-header'}>
          <p>Email Address</p>
          <span />
        </div>
        <ul className={'meta-pro_page-list'}>{renderMetaProList()}</ul>
      </div>
    </div>
  );
}

export default MetaProEditorPage;
