import { Http } from '../services/http';
import { API_BASE_URL, API_ENDPOINTS } from '../constants/api';
import { RegisterUser, RequestObject, SearchUser } from '../types/users';
import { useDispatch } from 'react-redux';
import {
  actionFindUser,
  actionGetEventEnterInfo,
  actionGetMetaProRequest,
  actionGetNFTsInfoByRoom,
  actionGetUserBySearch,
  actionGetUserRooms,
  actionGetUsers,
} from '../store/actions/usersActions';
import { RoomsSearchByUser } from '../types/rooms';

const useUsers = () => {
  const dispatch = useDispatch();
  const getUsersList = async (data: RequestObject) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.GET_USERS}`;
    const res = await Http.post(url, data);

    dispatch(actionGetUsers(res));

    return res;
  };

  const registerUser = async (data: RegisterUser) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.USER_REGISTER}`;
    const res = await Http.post(url, data);

    return res;
  };

  const getUserBySearch = async (data: SearchUser) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.GET_USER_BY_SEARCH}`;
    const res = await Http.post(url, data);

    if (res.length) {
      dispatch(actionGetUserBySearch(res));
    }

    return res;
  };

  const findUser = async (data: SearchUser) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.GET_USER_BY_SEARCH}`;
    const res = await Http.post(url, data);

    // const tempMap = new Map();

    // res.forEach((obj: User) => {
    //   tempMap.set(obj.WALLETID.toLowerCase(), obj);
    // });

    // const uniqueArr = Array.from(tempMap, ([, value]) => value);

    dispatch(actionFindUser(res));

    return res;
  };

  const getRoomsByUserId = async (data: RoomsSearchByUser) => {
    const { userid } = data;
    let { pagenum } = data;
    const rooms = [];
    const url = `${API_BASE_URL}${API_ENDPOINTS.GET_ROOMS_BY_USER_ID}`;

    while (true) {
      const res = await Http.post(url, { pagenum, userid });

      if (!res.length) {
        break;
      }

      pagenum++;
      rooms.push(...res);
    }

    dispatch(actionGetUserRooms(rooms));

    return rooms;
  };

  const getNFTbyRoomID = async (roomId: string) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.GET_NFT_BY_ROOM}`;
    const res = await Http.post(url, { roomid: roomId });

    dispatch(actionGetNFTsInfoByRoom(res.data));

    return res.data;
  };

  const getEventEnter = async (walletid: string) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.GET_EVENT_ENTER}`;
    const res = await Http.post(url, { walletid });

    dispatch(actionGetEventEnterInfo(res));

    return res;
  };

  const postUserEventReset = async (walletid: string) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.USER_EVENT_RESET}`;
    const res = await Http.post(url, { walletid });

    return res;
  };

  const toggleUnlimitedUserRooms = async (walletid: string) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.TOGGLE_UNLIMITED_USER_ROOMS}`;
    const res = await Http.post(url, { walletid });

    return res;
  };

  const toggleBanUser = async (walletid: string, banreason?: string) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.TOGGLE_BAN_USER}`;
    let res;

    if (banreason) {
      res = await Http.post(url, { walletid, banreason });
    } else {
      res = await Http.post(url, { walletid });
    }

    return res;
  };

  const toggleMessageBan = async (walletid: string) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.TOGGLE_MESSAGE_BAN}`;
    const res = await Http.post(url, { walletid });

    return res;
  };

  const getProAccessRequests = async (pagenum: number, email = '') => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.GET_PRO_ACCESS_REQUEST}`;
    const res = await Http.post(url, { pagenum, email });

    dispatch(actionGetMetaProRequest(res));

    return res;
  };

  const getRoomPurchases = async (emailOrWalletId: string) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.ADMIN_GET_ROOM_PURCHASES}`;
    const res = await Http.post(url, { emailOrWalletId });

    return res;
  };

  const getSingleProAccess = async (uid: string) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.GET_SINGLE_PRO_ACCESS}`;
    const res = await Http.post(url, { uid });

    return res;
  };

  return {
    getUsersList,
    getUserBySearch,
    getRoomsByUserId,
    findUser,
    getEventEnter,
    postUserEventReset,
    registerUser,
    toggleUnlimitedUserRooms,
    toggleMessageBan,
    toggleBanUser,
    getProAccessRequests,
    getSingleProAccess,
    getNFTbyRoomID,
    getRoomPurchases,
  };
};

export default useUsers;
