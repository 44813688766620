import React from 'react';
import { Props } from './ListChartTypes';
import './ListChart.scss';
import shortOwner from '../../utilsCommon/shortOwner';

const ListChart = ({ name, borderColor, headColor, headTextColor, tableHeadings, list }: Props) => {
  // format number
  // const formatter = new Intl.NumberFormat('en-US', {
  //   minimumFractionDigits: 0,
  // });

  return (
    <div className="list-chart">
      <div className="list-chart__inner">
        <h3 className="list-chart__heading">{name}</h3>
        <table
          className="list-chart__table"
          style={
            {
              '--border-color': borderColor,
              '--head-color': headColor,
              '--head-text-color': headTextColor,
            } as React.CSSProperties
          }
        >
          <thead>
            <tr className="list-chart__table-header">
              {tableHeadings.map((item, index) => (
                <th key={index} className="list-chart__table-head">
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {list.length ? (
              list.map((item, index) => {
                if ('sum' in item) {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.roomId}</td>
                      <td>{item.roomName}</td>
                      <td>{item.sum}</td>
                      <td>{shortOwner(item.roomOwner)}</td>
                    </tr>
                  );
                } else if ('roomId' in item) {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.roomId}</td>
                      <td>{item.name}</td>
                      <td className="modelUrl">
                        <a href={item.modelUrl} target="__blank">
                          Link
                        </a>
                      </td>
                      <td>{item.count}</td>
                      <td>{item.owner && shortOwner(item.owner)}</td>
                    </tr>
                  );
                } else {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>Clicks {item.count}</td>
                      <td>{item.name}</td>
                      <td className="modelUrl">
                        <a href={item.modelUrl} target="__blank">
                          Link
                        </a>
                      </td>
                      <td>{item.owner && shortOwner(item.owner)}</td>
                    </tr>
                  );
                }
              })
            ) : (
              <tr>
                <td colSpan={tableHeadings.length}>No data</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListChart;
