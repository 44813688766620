import { LogIn, Registration } from '../types/auth';
import ROUTES from './routerConstants';
import { UsersReducer } from '../store/reducers/usersReducer';
import { EmployeeReducer } from '../store/reducers/employeeReducer';
import { BanUser, NewUser, SendLink } from '../types/common';
import SVG_ICONS from './SVG';
import { RoomsReducer } from '../store/reducers/RoomsReducer';
import { ChartReducer } from '../store/reducers/chartReducer';
import { initialRoomCreateType } from '../components/RoomCreate/RoomCreate.type';

export const initialValuesLogin: LogIn = {
  email: '',
  password: '',
};
export const initialValuesSendLink: SendLink = {
  walletId: '',
  email: '',
};
export const initialValuesRegister: Registration = {
  email: '',
  password: '',
  token: '',
};
export const initialValuesNewUser: NewUser = {
  email: '',
};
export const initialBanUser: BanUser = {
  walletId: '',
  banReason: '',
};
export const initialCreateRoom: initialRoomCreateType = {
  error: '',
  // walletId: '',
  // email: '',
  sceneId: '',
  sceneName: '',
  sceneImageUrl: '',
  userWalletIdOrEmail: '',
  packageType: '',
  walletIdEmailAccessPass: '',
};

export const initialResetPasswords = {
  password: '',
  confirm: '',
};

export const sideBarItems = [
  {
    icon: SVG_ICONS.OVERVIEW,
    active_icon: SVG_ICONS.OVERVIEW_ACTIVE,
    name: 'Overview',
    to: ROUTES.OVERVIEW,
  },
  // {
  //   icon: SVG_ICONS.EVENTS,
  //   active_icon: SVG_ICONS.EVENTS_ACTIVE,
  //   name: 'Event Statistics',
  //   to: ROUTES.EVENTS,
  // },
  // {
  //   icon: SVG_ICONS.ANALYTICS,
  //   active_icon: SVG_ICONS.ANALYTICS_ACTIVE,
  //   name: 'Web Analytics',
  //   to: ROUTES.ANALYTICS,
  // },
  {
    icon: SVG_ICONS.USERS,
    active_icon: SVG_ICONS.USERS_ACTIVE,
    name: 'Users',
    to: ROUTES.USERS,
  },
  {
    icon: SVG_ICONS.ROOMS,
    active_icon: SVG_ICONS.ROOMS_ACTIVE,
    name: 'Rooms',
    to: ROUTES.ROOMS,
  },
  {
    icon: SVG_ICONS.EMPLOYEE,
    active_icon: SVG_ICONS.EMPLOYEE_ACTIVE,
    name: 'Employee Man.',
    to: ROUTES.EMPLOYEE_MANAGEMENT,
  },
  {
    icon: SVG_ICONS.EMPLOYEE,
    active_icon: SVG_ICONS.EMPLOYEE_ACTIVE,
    name: 'Meta Pro Editor',
    to: ROUTES.META_PRO_EDITOR,
  },
  // {
  //   icon: SVG_ICONS.USERS,
  //   active_icon: SVG_ICONS.USERS_ACTIVE,
  //   name: 'Companies',
  //   to: ROUTES.COMPANIES,
  // },
  {
    icon: SVG_ICONS.ROOMS,
    active_icon: SVG_ICONS.ROOMS_ACTIVE,
    name: 'Settings',
    to: ROUTES.SETTINGS,
  },
];
export const specificRoomItems = [
  'Room Name',
  'Room Status',
  'Room Link',
  'Room Description',
  'Date Created',
  'Room Type',
  'Room Owner',
  'Scene ID',
  'No. of Users in the Room',
];

export const usersReducerDefaultState: UsersReducer = {
  dataList: [],
  specialUser: {
    walletId: '',
    dateCreated: null,
    lastLogin: null,
    rooms: [],
    currentRoomInfo: [],
    showDetails: 999,
    active: 1,
    canMessage: 1,
    canUnlimited: 0,
    ipAddress: '',
    browser: '',
    banReason: 'N/A',
    email: 'N/A',
  },
  eventEnter: {
    EVENT_ENTER: '',
    ARENA_ACCESS: 0,
  },
  proAccess: [],
};
export const roomsReducerDefaultState: RoomsReducer = {
  dataList: [],
  specificRoom: {
    ISSUSPENDED: 0,
    ISUNLIMITEDACCESS: 0,
    ROOM_ID: '',
    ROOM_DESCRIPTION: '',
    ROOM_NAME: '',
    SCENE_DESCRIPTION: '',
    SCENE_LINK: '',
    SCENE_NAME: '',
    CREATED_DATE: '2000-10-10',
    LAST_UPDATED: '2000-10-10',
    UID: '',
    NFTPOSITIONSREF: '',
  },
  specificRoomSize: [],
  currentRoomNFTs: [],
};
export const employeeReducerDefaultState: EmployeeReducer = {
  currentEmployee: [],
  invitedEmployee: [],
};

export const employeeTabs: string[] = ['Current Users', 'Invited users', 'Add new user'];
export const usersTabs: string[] = ['Current Active Users', 'Banned Users'];
export const companiesTabs: string[] = ['Current Active Companies', 'Current Deactive'];
export const roomsTabs: string[] = ['List all Rooms', 'Featured Rooms', 'Trending Rooms', 'Latest Rooms'];
export const metaProTabs: string[] = ['List all Users'];
export const tableHeadings = ['Position', 'NFT/Frame', 'Name', 'Link', 'Owner'];
export const tableHeadingsMostClickedNft = ['Position', 'Room ID', 'Name', 'Link', 'Clicks', 'Owner'];
export const tableHeadingsRevenueRooms = ['Position', 'Room ID', 'Room Name', 'Revenue', 'Room Owner'];
export const chartReducerDefaultState: ChartReducer = {
  usersCreated: [],
  roomsCreated: [],
  nftPlaced: [],
  nftSold: [],
  accessPassSearch24: [],
  accessPassSearchMonth: [],
  accessPassSearchYear: [],
  totalAccessPassSearch: [],
  freeTrailUsersSearch: [],
  dailyUniqueUsers: [],
  monthlyUniqueUsers: [],
  adminRoomVisits: [],
  adminNftSoldSum: [],
  adminNftSoldCount: [],
  adminNftClicks: [],
  adminMostRevenueRooms: [],
  adminMostClickedNft: [],
  accessPassSearchLegend: [],
  adminNftSoldSumPerDay: [],
};

export const companyDetailRoomList = [
  {
    name: 'Test room',
    describe: 'Describe room',
    create_date: '2/21/2022',
    sceneId: 'Open',
    numberUsers: '23',
    link: 'https://google.com',
    suspend: 'https://google.com',
  },
  {
    name: 'Test room',
    describe: 'Describe room',
    create_date: '2/21/2022',
    sceneId: 'Open',
    numberUsers: '23',
    link: 'https://google.com',
    suspend: 'https://google.com',
  },
  {
    name: 'Test room',
    describe: 'Describe room',
    create_date: '2/21/2022',
    sceneId: 'Open',
    numberUsers: '23',
    link: 'https://google.com',
    suspend: 'https://google.com',
  },
];

export const companyDetailUsersList = [
  {
    email: 'jake@bluemoon.com',
    walletId: 'iugiuhguegreg',
    last_login: '2/10/2022',
  },
  {
    email: 'jake@bluemoon.com',
    walletId: 'iugiuhguegreg',
    last_login: '2/10/2022',
  },
  {
    email: 'jake@bluemoon.com',
    walletId: 'iugiuhguegreg',
    last_login: '2/10/2022',
  },
  {
    email: 'jake@bluemoon.com',
    walletId: 'iugiuhguegreg',
    last_login: '2/10/2022',
  },
];

interface IRoomType {
  [key: number]: string;
  [key: string]: string;
}

export const roomType: IRoomType = {
  1: 'Free',
  2: 'Standard',
  3: 'Pro',
  4: 'Custom',
};

interface ILockType {
  [key: number]: string;
  [key: string]: string;
}

export const lockType: ILockType = {
  1: 'All',
  2: 'Access Pass',
  3: 'Gold Pass',
  4: 'NFT Lock',
  5: 'Token Lock',
};
