import { Field, Form, Formik, FormikValues } from 'formik';
import './Login.scss';
import { initialValuesLogin } from '../../constants/defaultData';
import Button from '../../components/_common/Button/Button';
import { Link, useNavigate } from 'react-router-dom';
import ROUTES from '../../constants/routerConstants';
import useAuth from '../../hooks/useAuth';
import { useState } from 'react';
import SVG_ICONS from '../../constants/SVG';
import logo from '../../assets/static/images/bluemoon-logo-icon.png';

function Login() {
  const { logIn } = useAuth();
  const navigation = useNavigate();
  const [logInError, setLogInError] = useState<string>('');
  const [isHidden, setIsHidden] = useState<boolean>(true);

  return (
    <Formik
      initialValues={initialValuesLogin}
      onSubmit={async (values) => {
        setLogInError('');
        await logIn(values).then((data) => {
          if (data?.error) {
            setLogInError(data.error);
          } else {
            navigation(ROUTES.OVERVIEW);
          }
        });
      }}
    >
      {({ values }) => {
        return (
          <div className={'login_wrapper'}>
            <Form className={'login_container'}>
              <img src={logo} alt="main-logo" className="login_logo" />
              <h2 className={'login_title'}>Sign in</h2>
              <p className={'input_label'}>username</p>
              <Field
                placeholder={'Enter your user name'}
                className={'form_input'}
                name={'email'}
                value={values.email}
              />
              <p className={'input_label'}>password</p>

              <Field name={'password'} value={values.password}>
                {({ field }: FormikValues) => (
                  <div className={'form_input-container'}>
                    <input
                      className={'form_input password'}
                      placeholder={'Enter your password'}
                      type={isHidden ? 'password' : 'text'}
                      {...field}
                    />

                    <button type={'button'} className={'form_input-btn_pass'} onClick={() => setIsHidden(!isHidden)}>
                      {isHidden ? (
                        <img src={SVG_ICONS.EYE_SHOW} alt={'Eye show'} />
                      ) : (
                        <img src={SVG_ICONS.EYE_HIDE} alt={'Eye hide'} />
                      )}
                    </button>
                  </div>
                )}
              </Field>
              {logInError && <p className={'form_error'}>{logInError}</p>}
              <Button type={'submit'} className={'login_button'} title={'Login'} />
              <p className={'choice_text'}>Or</p>
              <Link className={'reset_link'} to={ROUTES.RESET_PASSWORD}>
                Reset password
              </Link>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}

export default Login;
