import React from 'react';
import { Navigate } from 'react-router-dom';
import ROUTES from '../../../constants/routerConstants';
import { USER_TOKEN_NAMESPACE } from '../../../constants/api';

interface RequireAuthProps {
  children: JSX.Element;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  return localStorage.getItem(USER_TOKEN_NAMESPACE) ? children : <Navigate replace to={ROUTES.LOGIN} />;
};

export default RequireAuth;
