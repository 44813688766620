import { createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import usersReducer, { UsersReducer } from './reducers/usersReducer';
import employeeReducer, { EmployeeReducer } from './reducers/employeeReducer';
import roomsReducer, { RoomsReducer } from './reducers/RoomsReducer';
import chartReducer, { ChartReducer } from './reducers/chartReducer';

export interface ApplicationState {
  users: UsersReducer;
  employee: EmployeeReducer;
  rooms: RoomsReducer;
  charts: ChartReducer;
}
export const reducer = combineReducers({
  users: usersReducer,
  employee: employeeReducer,
  rooms: roomsReducer,
  charts: chartReducer,
});

export const store = createStore(reducer, composeWithDevTools());
