import CHARTS_ACTION_TYPES from '../actionTypes/chartsActionTypes';
import {
  ChartAccessPassSearch24,
  ChartNFTData,
  ChartsData,
  ChartSoldNFTData,
  ChartAccessPassSearchMonth,
  ChartAccessPassSearchYear,
  ChartTotalAccessPassSearch,
  ChartFreeTrailUsersSearch,
  ChartDailyUniqueUsers,
  ChartMonthlyUniqueUsers,
  ChartAdminRoomVisits,
  ChartAdminNftSoldSum,
  ChartAdminNftSoldCount,
  ChartAdminNftClicks,
  ChartAdminMostRevenueRooms,
  ChartAdminMostClickedNft,
  ChartAccessPassSearchLegend,
  ChartAdminNftSoldSumPerDay,
} from '../../types/charts';

export const actionGetNumberOfUsers = (data: ChartsData) => {
  return {
    type: CHARTS_ACTION_TYPES.GET_NUMBER_OF_USERS_CREATED,
    data,
  };
};

export const actionGetNumberOfRooms = (data: ChartsData) => {
  return {
    type: CHARTS_ACTION_TYPES.GET_NUMBER_OF_ROOMS_CREATED,
    data,
  };
};

export const actionGetNumberOfSoldNft = (data: ChartSoldNFTData) => {
  return {
    type: CHARTS_ACTION_TYPES.GET_NFT_SOLD,
    data,
  };
};

export const actionGetNumberOfPlacedNFT = (data: ChartNFTData) => {
  return {
    type: CHARTS_ACTION_TYPES.GET_NUMBER_OF_PLACED_NFT,
    data,
  };
};

export const actionGetAccessPassSearch24 = (data: ChartAccessPassSearch24) => {
  return {
    type: CHARTS_ACTION_TYPES.GET_ACCESS_PASS_SEARCH_24,
    data,
  };
};

export const actionGetAccessPassSearchMonth = (data: ChartAccessPassSearchMonth) => {
  return {
    type: CHARTS_ACTION_TYPES.GET_ACCESS_PASS_SEARCH_MONTH,
    data,
  };
};

export const actionGetAccessPassSearchYear = (data: ChartAccessPassSearchYear) => {
  return {
    type: CHARTS_ACTION_TYPES.GET_ACCESS_PASS_SEARCH_YEAR,
    data,
  };
};

export const actionGetTotalAccessPassSearch = (data: ChartTotalAccessPassSearch) => {
  return {
    type: CHARTS_ACTION_TYPES.GET_TOTAL_ACCESS_PASS_SEARCH,
    data,
  };
};

export const actionGetFreeTrailUsersSearch = (data: ChartFreeTrailUsersSearch) => {
  return {
    type: CHARTS_ACTION_TYPES.GET_FREE_TRAIL_USERS_SEARCH,
    data,
  };
};

export const actionGetDailyUniqueUsers = (data: ChartDailyUniqueUsers) => {
  return {
    type: CHARTS_ACTION_TYPES.GET_DAILY_UNIQUE_USERS,
    data,
  };
};

export const actionGetMonthlyUniqueUsers = (data: ChartMonthlyUniqueUsers) => {
  return {
    type: CHARTS_ACTION_TYPES.GET_MONTHLY_UNIQUE_USERS,
    data,
  };
};

export const actionGetAdminRoomVisits = (data: ChartAdminRoomVisits) => {
  return {
    type: CHARTS_ACTION_TYPES.GET_ADMIN_ROOM_VISITS,
    data,
  };
};

export const actionGetAdminNftSoldSum = (data: ChartAdminNftSoldSum) => {
  return {
    type: CHARTS_ACTION_TYPES.GET_ADMIN_NFT_SOLD_SUM,
    data,
  };
};

export const actionGetAdminNftSoldCount = (data: ChartAdminNftSoldCount) => {
  return {
    type: CHARTS_ACTION_TYPES.GET_ADMIN_NFT_SOLD_COUNT,
    data,
  };
};

export const actionGetAdminNftClicks = (data: ChartAdminNftClicks) => {
  return {
    type: CHARTS_ACTION_TYPES.GET_ADMIN_NFT_CLICKS,
    data,
  };
};

export const actionGetAdminMostRevenueRooms = (data: ChartAdminMostRevenueRooms) => {
  return {
    type: CHARTS_ACTION_TYPES.GET_ADMIN_MOST_REVENUE_ROOMS,
    data,
  };
};

export const actionGetAdminMostClickedNft = (data: ChartAdminMostClickedNft) => {
  return {
    type: CHARTS_ACTION_TYPES.GET_ADMIN_MOST_CLICKED_NFT,
    data,
  };
};

export const actionGetAccessPassSearchLegend = (data: ChartAccessPassSearchLegend) => {
  return {
    type: CHARTS_ACTION_TYPES.GET_ACCESS_PASS_SEARCH_LEGEND,
    data,
  };
};

export const actionGetAdminNftSoldSumPerDay = (data: ChartAdminNftSoldSumPerDay) => {
  return {
    type: CHARTS_ACTION_TYPES.GET_ADMIN_NFT_SOLD_SUM_PER_DAY,
    data,
  };
};
