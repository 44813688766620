import { useEffect, useRef, useState } from 'react';

const useCountDownTimer = (CREATED_DATE: Date | string) => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [countDownValue, setCountDownValue] = useState(0);

  const initializeDate = () => {
    // if (typeof CREATED_DATE === 'string') {
    //   if (CREATED_DATE.includes('0000-00-00')) {
    //     return -1;
    //   }
    // }

    const roomCreatedDate = new Date(CREATED_DATE);
    // const roomCreatedDate = new Date('2022-08-15T12:57:46.000Z');

    roomCreatedDate.setDate(roomCreatedDate.getDate() + 3);

    const today = new Date();

    if (today.getTime() - roomCreatedDate.getTime() >= 0) {
      clearInterval(intervalRef.current as NodeJS.Timeout);

      return 0;
    }

    return roomCreatedDate.getTime() - today.getTime();
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCountDownValue(initializeDate());
    }, 1000);

    return () => clearInterval(intervalRef.current as NodeJS.Timeout);
  }, [countDownValue, CREATED_DATE]);

  return { countDownValue };
};

export default useCountDownTimer;
