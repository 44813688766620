import React, { useState, useEffect } from 'react';
import './SpecialUserRoomsItem.scss';
import { SpecialUserRoomsItem } from '../../types/users';
import getLocalDate from '../../utilsCommon/UtilsCommon';
import SVG_ICONS from '../../constants/SVG';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { actionShowNFTsDetails } from '../../store/actions/usersActions';
import Modal from '../_common/Modal/Modal';
import Checkbox from '../_common/Checkbox/Checkbox';
import useRooms from '../../hooks/useRooms';
import useCountDownTimer from '../../hooks/useCountDownTimer';
import { USER_TOKEN_NAMESPACE } from '../../constants/api';
import useUsers from '../../hooks/useUsers';
import { Room } from '../../types/rooms';
import { lockType } from '../../constants/defaultData';

interface Props {
  item: SpecialUserRoomsItem;
  index: number;
}

function SpecialUserItem({ item, index }: Props) {
  const { ROOM_NAME, ROOM_DESCRIPTION, LOCK_TYPE, CREATED_DATE, LAST_UPDATED, ROOM_ID, ISSUSPENDED } = item;

  const dispatch = useDispatch();
  const { switchSuspendRoom, getFeaturedRooms, getTrendingRooms } = useRooms();
  const { getNFTbyRoomID } = useUsers();
  const roomsGraphql = useSelector((state: ApplicationState) => state.rooms.specificRoomSize);
  const { currentRoomInfo, showDetails } = useSelector((state: ApplicationState) => state.users.specialUser);
  const [isModal, setIsModal] = useState(false);
  const [isSuspend, setIsSuspend] = useState(Boolean(ISSUSPENDED));
  const { countDownValue } = useCountDownTimer(CREATED_DATE);
  const [isFeature, setIsFeature] = useState(false);
  const [isTrending, setIsTrending] = useState(false);

  const handleSuspendClick = async () => {
    const res = await switchSuspendRoom(ROOM_ID);

    if (res.success === 'Room suspension is now set to 1') {
      setIsSuspend(true);
    } else if (res.success === 'Room suspension is now set to 0') {
      setIsSuspend(false);
    }

    setIsModal(false);
  };

  const renderSuspendBlock = isSuspend ? (
    <>
      <h2>Confirm room unsuspend?</h2>
      <Checkbox
        label={'Suspend Room'}
        btnText={'Accept'}
        handleClick={handleSuspendClick}
        handleChange={() => setIsSuspend(!isSuspend)}
        isDisabled={false}
        isChecked={isSuspend}
        name={'suspend'}
      />
    </>
  ) : (
    <>
      <h2>Confirm room suspension?</h2>
      <Checkbox
        label={'Suspend Room'}
        btnText={'Accept'}
        handleClick={handleSuspendClick}
        handleChange={() => setIsSuspend(!isSuspend)}
        isDisabled={false}
        isChecked={isSuspend}
        name={'suspend'}
      />
    </>
  );

  const clickHandler = (currentItemIndex: number) => {
    if (currentItemIndex !== showDetails) {
      dispatch(actionShowNFTsDetails(currentItemIndex));
      getNFTbyRoomID(ROOM_ID);
    } else {
      dispatch(actionShowNFTsDetails(999));
    }
  };

  const listNFTs = currentRoomInfo.filter(({ NAME }) => Boolean(NAME));
  const listImages = currentRoomInfo.filter(({ NAME }) => !Boolean(NAME));

  const renderNFTsInfo = () =>
    listNFTs.map(({ DESCRIPTION, ACTION_DATE, NAME, ASSETSTORAGEREF }, key) => {
      return (
        <div className={'ntf_item'} key={key}>
          <p>{NAME ?? '-'}</p>
          <p>{DESCRIPTION ?? '-'}</p>
          <p>{ASSETSTORAGEREF}</p>
          <p>{getLocalDate(ACTION_DATE)}</p>
        </div>
      );
    });
  const renderImagesInfo = () =>
    listImages.map(({ ASSETSTORAGEREF, ACTION_DATE }, key) => {
      return (
        <div className={'ntf_item'} key={key}>
          <p>{ASSETSTORAGEREF}</p>
          <p></p>
          <p></p>
          <p>{getLocalDate(ACTION_DATE)}</p>
        </div>
      );
    });

  const renderRoomStatus = countDownValue === 0 ? 'Closed' : countDownValue === -1 ? 'Unlimited' : 'Open';

  const roomGraphql = roomsGraphql.find((room) => room.id === ROOM_ID) || { memberCount: '-' };

  const token = window.localStorage.getItem(USER_TOKEN_NAMESPACE);

  useEffect(() => {
    getFeaturedRooms().then((rooms) => {
      const featuredRoom = rooms.find((room: Room) => room.ROOM_ID === ROOM_ID);

      if (featuredRoom) {
        setIsFeature(true);
      }
    });

    getTrendingRooms().then((rooms) => {
      const trendingRoom = rooms.find((room: Room) => room.ROOM_ID === ROOM_ID);

      if (trendingRoom) {
        setIsTrending(true);
      }
    });
  }, [ROOM_ID]);

  if (countDownValue === 0) {
    return null;
  }

  return (
    <>
      <Modal open={isModal} onClose={() => setIsModal(false)}>
        <div className={'rooms-item__modal'}>{renderSuspendBlock}</div>
      </Modal>
      <div className={'rooms-item'}>
        <p>{ROOM_NAME}</p>
        <p>{ROOM_DESCRIPTION}</p>
        <p>{lockType[LOCK_TYPE] || ''}</p>
        <p>{isFeature ? 'Yes' : 'No'}</p>
        <p>{isTrending ? 'Yes' : 'No'}</p>
        <p>{getLocalDate(CREATED_DATE)}</p>
        <p>{getLocalDate(LAST_UPDATED)}</p>
        <p>{roomGraphql!.memberCount}</p>
        <a href={`https://metaverse.bluemoon.io/${ROOM_ID}?entry=${token}`} target="_blank" rel="noreferrer">
          Open
        </a>
        <p>{renderRoomStatus}</p>
        <button onClick={() => setIsModal(true)}>{isSuspend ? 'Unsuspend' : 'Suspend'}</button>
        <img
          style={showDetails === index ? { transform: 'rotate(180deg)' } : { transform: 'rotate(360deg)' }}
          onClick={() => clickHandler(index)}
          src={SVG_ICONS.ARROW_UP}
          alt="extend info"
        />
      </div>
      <div className={'rooms-item_NFTs'} style={showDetails === index ? { display: 'block' } : { display: 'none' }}>
        <div className={'rooms-item_NFTs-header'}>
          <p>NFT Name</p>
          <p>NFT Description</p>
          <p>Model URL</p>
          <p>Date Placed</p>
        </div>
        {listNFTs.length ? renderNFTsInfo() : <div className={'rooms-item_NFTs-no-info'}>No info</div>}
      </div>
      <div className={'rooms-item_NFTs'} style={showDetails === index ? { display: 'block' } : { display: 'none' }}>
        <div className={'rooms-item_NFTs-header'}>
          <p>Model URL</p>
          <p></p>
          <p></p>
          <p>Date Placed</p>
        </div>
        {listImages.length ? renderImagesInfo() : <div className={'rooms-item_NFTs-no-info'}>No info</div>}
      </div>
    </>
  );
}

export default SpecialUserItem;
