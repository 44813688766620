import React, { useEffect, useState } from 'react';
import './UsersPage.scss';
import useUsers from '../../hooks/useUsers';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import PagesHeader from '../../components/PagesHeader/PagesHeader';
import Tabs from '../../components/Tabs/Tabs';
import { initialBanUser, usersTabs } from '../../constants/defaultData';
import getLocalDate from '../../utilsCommon/UtilsCommon';
import SVG_ICONS from '../../constants/SVG';
import { Link } from 'react-router-dom';
import ROUTES from '../../constants/routerConstants';
import { Field, Form, Formik } from 'formik';
// import Button from '../../components/_common/Button/Button';
// import validate from '../../constants/validate';
// import { RegisterUser } from '../../types/users';

function UsersPage() {
  const { getUsersList, toggleBanUser } = useUsers();
  // const { getUsersList, registerUser } = useUsers();
  const [currentTab, setCurrentTab] = useState<number>(0);
  // const [emailSentText, setEmailSentText] = useState<string>('');
  const [isAddUser] = useState(false);
  const [isBanUser, setIsBanUser] = useState(false);
  const { dataList } = useSelector((state: ApplicationState) => state.users);

  const renderUserList = () =>
    dataList &&
    dataList
      .filter(({ IS_ACTIVE }) => {
        return IS_ACTIVE === 1;
      })
      .map(({ CREATED_DATE, EMAIL, WALLETID, UID, LAST_LOGIN, IS_ACTIVE }, index) => {
        return (
          <li key={index} className={'user_page-list_item'}>
            <p className={'email'}>{EMAIL || WALLETID}</p>
            <p className={'date'}>{getLocalDate(CREATED_DATE)}</p>
            <p className={'date'}>{getLocalDate(LAST_LOGIN)}</p>
            <Link to={`${ROUTES.USERS}/${WALLETID}/${UID}/${IS_ACTIVE}`}>
              <img src={SVG_ICONS.ARROW_RIGHT} alt="open user info" />
            </Link>
          </li>
        );
      });

  const renderBanUsersList = () =>
    dataList &&
    dataList
      .filter(({ IS_ACTIVE }) => {
        return IS_ACTIVE === 0;
      })
      .map(({ CREATED_DATE, WALLETID, UID, LAST_LOGIN, IS_ACTIVE }, index) => {
        return (
          <li key={index} className={'user_page-list_item'}>
            <p className={'email'}>{WALLETID}</p>
            <p className={'date'}>{getLocalDate(CREATED_DATE)}</p>
            <p className={'date'}>{getLocalDate(LAST_LOGIN)}</p>
            <Link to={`${ROUTES.USERS}/${WALLETID}/${UID}/${IS_ACTIVE}`}>
              <img src={SVG_ICONS.ARROW_RIGHT} alt="open user info" />
            </Link>
          </li>
        );
      });

  useEffect(() => {
    getUsersList({ pagenum: 1 });
  }, [isBanUser]);

  useEffect(() => {
    setIsBanUser(false);
  }, [currentTab]);

  return (
    <div className={'user_page'}>
      <Tabs tabs={usersTabs} changeTabHandler={setCurrentTab} currentTab={currentTab} />
      {currentTab === 0 && !isAddUser && (
        <>
          <PagesHeader />
          <div className={'user_page_list_wrapper'}>
            <div className={'user_page-header'}>
              <p>Wallet ID/Email</p>
              <p>Date of Creation</p>
              <p>Last Login</p>
              <span />
            </div>
            <ul className={'user_page-list'}>{renderUserList()}</ul>
          </div>
        </>
      )}

      {/*{currentTab === 0 && isAddUser && (*/}
      {/*  <Formik*/}
      {/*    initialValues={initialValuesNewUser}*/}
      {/*    onSubmit={async (values, actions) => {*/}
      {/*      const user: RegisterUser = {*/}
      {/*        walletid: values.email,*/}
      {/*      };*/}

      {/*      const res = await registerUser(user);*/}

      {/*      if (res.data) {*/}
      {/*        setEmailSentText('Email sent');*/}
      {/*        setIsAddUser(false);*/}
      {/*      } else {*/}
      {/*        setEmailSentText('Something went wrong');*/}
      {/*      }*/}

      {/*      actions.resetForm();*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    {({ values, errors }) => (*/}
      {/*      <Form className={'user_page-new_user'}>*/}
      {/*        <p className={'user_page-new_user-label'}>Enter Email Address for the New User</p>*/}
      {/*        <Field*/}
      {/*          placeholder={'Enter User Email'}*/}
      {/*          className={`user_page-new_user-email ${errors.email ? 'error' : ''}`}*/}
      {/*          name={'email'}*/}
      {/*          value={values.email}*/}
      {/*          validate={validate}*/}
      {/*        />*/}
      {/*        <Button type={'submit'} className={'user_page-new_user-btn_enter'} title={'Enter'} />*/}
      {/*        {emailSentText.length !== 0 && <div className={'user_page-new_user-sent'}>{emailSentText}!</div>}*/}
      {/*      </Form>*/}
      {/*    )}*/}
      {/*  </Formik>*/}
      {/*)}*/}

      {currentTab === 1 && !isBanUser && (
        <>
          <PagesHeader btnText="Ban a user" handleClick={() => setIsBanUser(true)} />
          <div className={'user_page_list_wrapper'}>
            <div className={'user_page-header'}>
              <p>Email Address</p>
              <p>Date of Creation</p>
              <p>Banned Date</p>
              <span />
            </div>
            <ul className={'user_page-list'}>{renderBanUsersList()}</ul>
          </div>
        </>
      )}

      {currentTab === 1 && isBanUser && (
        <Formik
          initialValues={initialBanUser}
          onSubmit={async (values) => {
            const { walletId, banReason } = values;
            const status = await toggleBanUser(walletId, banReason);

            if (status.success) {
              setIsBanUser(false);
            }
          }}
        >
          {() => (
            <Form className={'ban-user_form'}>
              <Field type={'text'} name={'walletId'} placeholder={'Enter Wallet ID'} className={'ban-user_input'} />
              <Field type={'text'} name={'banReason'} placeholder={'Ban Reason'} className={'ban-user_input'} />
              <button type="submit" className={'ban-user_btn'}>
                Ban user
              </button>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}

export default UsersPage;
