import React from 'react';
import './EventsPage.scss';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import useCharts from '../../hooks/useCharts';
import getLocalDate from '../../utilsCommon/UtilsCommon';
import { Field, Form, Formik, FormikErrors, FormikValues } from 'formik';
import Button from '../../components/_common/Button/Button';
import LineChart from '../../components/LineChart/LineChart';

const validate = (values: FormikValues) => {
  const errors: FormikErrors<FormikValues> = {};

  if (!values.startDate) {
    errors.startDate = 'Required';
  } else if (!values.endDate) {
    errors.endDate = 'Required';
  }

  return errors;
};

const EventsPage = () => {
  const { usersCreated, roomsCreated, nftSold, nftPlaced } = useSelector((state: ApplicationState) => state.charts);
  const { getNumberOfUsers, getNumberOfSoldNft, getNumberOfRooms, getNumberOfPlacedNFT } = useCharts();

  const userData = () => usersCreated.map(({ COUNT }) => COUNT);
  const userLabels = () => usersCreated.map(({ CREATED_DATE }) => getLocalDate(CREATED_DATE));
  const roomsData = () => roomsCreated.map(({ COUNT }) => COUNT);
  const roomsLabels = () => roomsCreated.map(({ CREATED_DATE }) => getLocalDate(CREATED_DATE));
  const nftData = () => nftSold.map(({ NUMOFSOLD }) => NUMOFSOLD);
  const nftLabels = () => nftSold.map(({ DATE }) => getLocalDate(DATE));
  const nftPlacedData = () => nftPlaced.map(({ COUNT }) => COUNT);
  const nftPlacedLabels = () => nftPlaced.map(({ ACTION_DATE }) => getLocalDate(ACTION_DATE));

  const rangeInputs = () => {
    return (
      <Formik
        initialValues={{ startDate: '', endDate: '' }}
        validate={validate}
        onSubmit={(values) => {
          getNumberOfUsers({ beforedate: values.endDate.toString(), afterdate: values.startDate.toString() });
          getNumberOfSoldNft({ todate: values.endDate.toString(), fromdate: values.startDate.toString() });
          getNumberOfRooms({ beforedate: values.endDate.toString(), afterdate: values.startDate.toString() });
          getNumberOfPlacedNFT({ beforedate: values.endDate.toString(), afterdate: values.startDate.toString() });
        }}
      >
        {({ handleChange, values, dirty, isValid }) => {
          return (
            <Form className={'events-form'}>
              <p>From</p>
              <p>To</p>
              <span />
              <Field
                type={'date'}
                className={'form_input'}
                onChange={handleChange('startDate')}
                value={values.startDate}
                name={'startDate'}
              />
              <Field
                name={'endDate'}
                type={'date'}
                className={'form_input'}
                onChange={handleChange('endDate')}
                value={values.endDate}
              />
              <Button className={'dateConfirm'} title={'Apply'} type={'submit'} disabled={!(dirty && isValid)} />
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <div className={'events'}>
      <div className={'events-header'}>
        <h2>Range Selection</h2>
        <div className={'range-container'}>{rangeInputs()}</div>
      </div>
      <div className={'events-charts'}>
        <LineChart color={'#EF466F'} title={'Number of Registrations'} data={userData()} labels={userLabels()} />
        <LineChart color={'#9757D7'} title={'Number of Attendees'} data={roomsData()} labels={roomsLabels()} />
        <LineChart color={'#45B36B'} title={'Revenue Generated'} data={nftData()} labels={nftLabels()} />
        <LineChart
          color={'#F9C50B'}
          title={'Average time spent in Event'}
          data={nftPlacedData()}
          labels={nftPlacedLabels()}
        />
      </div>
    </div>
  );
};

export default EventsPage;
