import { useDispatch } from 'react-redux';
import { RequestObject } from '../types/users';
import { API_BASE_URL, API_ENDPOINTS } from '../constants/api';
import { Http } from '../services/http';
import {
  actionGetCurrentEmployee,
  actionGetInvitedEmployee,
  actionRevokeInvite,
} from '../store/actions/employeeActions';

const useEmployee = () => {
  const dispatch = useDispatch();
  const getInvitedEmployee = async (data: RequestObject) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.INVITE_TOKEN_LIST}`;
    const res = await Http.post(url, data);

    dispatch(actionGetInvitedEmployee(res));

    return res;
  };

  const getCurrentEmployee = async (data: RequestObject) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.CURRENT_EMPLOYEE}`;
    const res = await Http.post(url, data);

    dispatch(actionGetCurrentEmployee(res));

    return res;
  };

  const cancelEmployeeInvite = async (token: string) => {
    dispatch(actionRevokeInvite(token));
    const url = `${API_BASE_URL}${API_ENDPOINTS.CANCEL_INVITE}`;

    await Http.post(url, { token: token });
  };

  const sendRegisterLink = async (email: string) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.SEND_INVITE}`;
    const res = Http.post(url, { email: email });

    return res;
  };

  return { getInvitedEmployee, getCurrentEmployee, cancelEmployeeInvite, sendRegisterLink };
};

export default useEmployee;
