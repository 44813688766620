const getLocalDate = (date: Date) => new Date(date).toLocaleDateString();

export const getFormatTimeValue = (countDown: number) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const daysString = days < 10 ? `0${days}` : days;

  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const hoursString = hours < 10 ? `0${hours}` : hours;

  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const minutesString = minutes < 10 ? `0${minutes}` : minutes;

  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
  const secondsString = seconds < 10 ? `0${seconds}` : seconds;

  return `${daysString}d :${hoursString}h :${minutesString}m :${secondsString}s`;
};

export default getLocalDate;
