import chevronLeft from '../assets/static/svg/chevron-left.svg';
import chevronRight from '../assets/static/svg/chevron-right.svg';
import main_logo from '../assets/static/svg/bluemoon-logo.svg';
import overview from '../assets/static/svg/overview.svg';
import users from '../assets/static/svg/users.svg';
import rooms from '../assets/static/svg/rooms.svg';
import employee from '../assets/static/svg/employee.svg';
import overview_active from '../assets/static/svg/overview_active.svg';
import users_active from '../assets/static/svg/users_active.svg';
import rooms_active from '../assets/static/svg/rooms_active.svg';
import employee_active from '../assets/static/svg/employee_active.svg';
import search from '../assets/static/svg/search.svg';
import arrowRight from '../assets/static/svg/arrow-right.svg';
import arrowUp from '../assets/static/svg/arrow-up.svg';
import close from '../assets/static/svg/close.svg';
import eyeShow from '../assets/static/svg/eye-show.svg';
import eyeHide from '../assets/static/svg/eye-hide.svg';
import circlePlus from '../assets/static/svg/circle-plus.svg';
import analytics from '../assets/static/svg/analytics.svg';
import analytics_active from '../assets/static/svg/analytics_active.svg';
import events from '../assets/static/svg/events.svg';
import events_active from '../assets/static/svg/events_active.svg';

const SVG_ICONS = {
  CHEVRON_LEFT: chevronLeft,
  CHEVRON_RIGHT: chevronRight,
  MAIN_LOGO: main_logo,
  OVERVIEW: overview,
  USERS: users,
  ROOMS: rooms,
  EMPLOYEE: employee,
  OVERVIEW_ACTIVE: overview_active,
  USERS_ACTIVE: users_active,
  ROOMS_ACTIVE: rooms_active,
  EMPLOYEE_ACTIVE: employee_active,
  SEARCH: search,
  ARROW_RIGHT: arrowRight,
  ARROW_UP: arrowUp,
  CLOSE: close,
  EYE_SHOW: eyeShow,
  EYE_HIDE: eyeHide,
  CIRCLE_PLUS: circlePlus,
  ANALYTICS: analytics,
  ANALYTICS_ACTIVE: analytics_active,
  EVENTS: events,
  EVENTS_ACTIVE: events_active,
};

export default SVG_ICONS;
