const checkDate = (date: Date | string) => {
  const roomCreatedDate = new Date(date);
  // const roomCreatedDate = new Date('2022-08-15T12:57:46.000Z');

  roomCreatedDate.setDate(roomCreatedDate.getDate() + 3);

  const today = new Date();

  if (today.getTime() - roomCreatedDate.getTime() >= 0) {
    return 0;
  }

  return roomCreatedDate.getTime() - today.getTime();
};

export default checkDate;
