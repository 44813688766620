import React from 'react';
import './LayOut.scss';
import { Outlet } from 'react-router-dom';
import SideBar from '../SideBar/SideBar';
import Button from '../_common/Button/Button';
import useAuth from '../../hooks/useAuth';

const LayOut: React.FC = () => {
  const { logOut } = useAuth();

  return (
    // <div className={'layout'}>
    <div className={'layout_content'}>
      <SideBar />
      <div className={'layout_content-page'}>
        <div className={'layout_header'}>
          <h2 className={'layout_header-title'}>Bluemoon Admin Dashboard</h2>
          <Button className={'log_out_btn'} title={'Log Out'} clickHandler={logOut} />
        </div>
        <Outlet />
      </div>
    </div>
    // </div>
  );
};

export default LayOut;
