import React, { useEffect, useState } from 'react';
import { ReactComponent as PenIcon } from '../../../assets/static/svg/pen-icon.svg';
import './CustomInput.scss';
import useRooms from '../../../hooks/useRooms';
import Button from '../Button/Button';

interface CustomInputProps {
  value: string | number | readonly string[] | undefined;
  id: string;
  index: number;
}

function CustomInput({ value, id, index }: CustomInputProps) {
  const { editRoomName, editRoomDescription } = useRooms();
  const [isDisabled, setIsDisabled] = useState(true);
  const [defaultValue, setDefaultValue] = useState<string | number | readonly string[] | undefined>(value);
  const onClickHandler = () => {
    setIsDisabled(!isDisabled);
  };

  useEffect(() => {
    setDefaultValue(value);
  }, [value]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDefaultValue(e.target.value);
  };

  const editInput = () => {
    if (index === 0) {
      return editRoomName(id, defaultValue as string);
    }

    if (index === 3) {
      return editRoomDescription(id, defaultValue as string);
    }
  };

  const onSubmitHandler = () => {
    setIsDisabled(!isDisabled);
    editInput();
  };

  return (
    <div className="custom-input">
      <input onChange={onChangeHandler} className="info" value={defaultValue} disabled={isDisabled} type="text" />
      {isDisabled ? (
        <Button className="custom-input_edit-btn" type="button" clickHandler={onClickHandler}>
          <PenIcon className="icon" width={10} height={10} />
        </Button>
      ) : (
        <Button className="custom-input_submit-btn" type="submit" title="Submit" clickHandler={onSubmitHandler} />
      )}
    </div>
  );
}

export default CustomInput;
