import React, { useEffect, useState } from 'react';
import './SideBar.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import { sideBarItems } from '../../constants/defaultData';
import logo from '../../assets/static/images/bluemoon-logo-icon.png';

function SideBar() {
  const navigate = useNavigate();
  const [active, setActive] = useState<string>('Overview');
  const changeSection = (path: string, name: string) => {
    navigate(path);
    setActive(name);
  };

  useEffect(() => {
    const pathName = location.pathname;
    const currentPath = pathName.split('/')[1];
    const currentLink = sideBarItems.find((link) => link.to.includes(currentPath));

    if (currentLink) {
      setActive(currentLink.name);
    }
  }, []);

  const renderSideBar = () =>
    sideBarItems.map(({ name, to, icon, active_icon }, index) => {
      return (
        <li id={name} key={index} onClick={() => changeSection(to, name)} className={'sidebar_list-item'}>
          <NavLink className="header__link" to={to}>
            <img src={active === name ? active_icon : icon} alt="list icon info" />
            <p>{name}</p>
          </NavLink>
        </li>
      );
    });

  return (
    <div className={'sidebar'}>
      <img className={'sidebar_logo'} src={logo} alt="main logo" />
      <ul className={'sidebar_list'}> {renderSideBar()}</ul>
    </div>
  );
}

export default SideBar;
