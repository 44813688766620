import { Http } from '../services/http';
import { API_BASE_URL, API_ENDPOINTS, API_GRAPHIQL } from '../constants/api';
import { IListNftSales, RequestObject, SearchRooms } from '../types/users';
import { useDispatch } from 'react-redux';
import {
  actionFindRoom,
  actionGetNftByRoom,
  actionGetRooms,
  actionGetRoomSize,
  actionGetSpecificRoom,
} from '../store/actions/roomsActions';

const useRooms = () => {
  const dispatch = useDispatch();
  const getRooms = async (data: RequestObject) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.GET_ROOMS}`;
    const res = await Http.post(url, data);

    dispatch(actionGetRooms(res));

    return res;
  };

  const getTrendingRooms = async () => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.GET_TRENDING_ROOMS}`;
    const res = await Http.get(url);

    dispatch(actionGetRooms(res));

    return res;
  };

  const getFeaturedRooms = async () => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.GET_FEATURED_ROOMS}`;
    const res = await Http.get(url);

    dispatch(actionGetRooms(res));

    return res;
  };

  const findRoom = async (data: SearchRooms) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.FIND_ROOM}`;
    const res = await Http.post(url, data);

    dispatch(actionFindRoom(res));

    return res;
  };

  const getSpecificRoomInfo = async (data: SearchRooms) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.FIND_ROOM}`;
    const res = await Http.post(url, data);

    dispatch(actionGetSpecificRoom(res));

    return res;
  };

  const getRoomNFTs = async (roomId: string) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.GET_NFT_BY_ROOM}`;
    const res = await Http.post(url, { roomid: roomId });

    dispatch(actionGetNftByRoom(res.data));

    return res.data;
  };

  const adminListNftSales = async (roomId: string) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.ADMIN_LIST_NFT_SALES}`;
    const res: IListNftSales[] | [] = await Http.post(url, { roomId });

    return res;
  };

  const switchSuspendRoom = async (roomId: string) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.SWITCH_SUSPEND_ROOM}`;
    const res = await Http.post(url, { roomid: roomId });

    return res;
  };

  const switchTrendRoom = async (roomid: string) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.SWITCH_TRENDING_ROOM}`;
    const res = await Http.post(url, { roomid });

    return res;
  };

  const switchFeatureRoom = async (roomId: string) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.SWITCH_FEATURE_ROOM}`;
    const res = await Http.post(url, { roomid: roomId });

    return res;
  };

  const switchUnlimitedSpecificRoom = async (roomid: string) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.TOGGLE_UNLIMITED_USER_SPECIFIC_ROOM}`;
    const res = await Http.post(url, { roomid });

    return res;
  };

  const getRoomSize = async () => {
    const query = 'query{myRooms(pageSize:100000){entries{id,memberCount,roomSize}}}';
    const url = `${API_GRAPHIQL}`;
    const extra_headers = {
      Authorization: 'Bearer 2fc4PHz.bmhFMFRYMEtMY0VxbFdYT2tQaXpnU3Jq',
    };

    const res = await Http.post(url, { query }, extra_headers);

    const {
      data: {
        myRooms: { entries },
      },
    } = await res;

    dispatch(actionGetRoomSize(entries));

    return entries;
  };

  const postRoomSize = async (roomid: string, roomsize: number) => {
    // const query = `mutation {updateRoom(id: "${roomId}",roomSize: ${roomSize}){id, roomSize}}`;
    // const url = `${API_GRAPHIQL}`;
    // const extra_headers = {
    //   Authorization: 'Bearer Z4zwWw6.SGtKWEtLM3pZV3NCdFJiaXBQaFBFRk1V',
    // };

    const url = `${API_BASE_URL}${API_ENDPOINTS.UPDATE_ROOM_SIZE}`;

    const res = await Http.post(url, { roomid, roomsize });

    return res;
  };

  const getWalletIdByUID = async (uid: string): Promise<{ WALLETID: string }> => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.GET_WALLETID_BY_UID}`;
    const res = await Http.post(url, { uid });

    return res;
  };

  const editRoomName = async (roomId: string, roomName: string) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.EDIT_ROOM_NAME}`;
    const res = await Http.post(url, { roomId, roomName });

    return res;
  };

  const editRoomDescription = async (roomId: string, roomDescription: string) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.EDIT_ROOM_DESCRIPTION}`;
    const res = await Http.post(url, { roomId, roomDescription });

    return res;
  };

  return {
    getRooms,
    findRoom,
    getTrendingRooms,
    getSpecificRoomInfo,
    switchSuspendRoom,
    switchFeatureRoom,
    switchTrendRoom,
    switchUnlimitedSpecificRoom,
    getRoomSize,
    postRoomSize,
    getFeaturedRooms,
    getRoomNFTs,
    getWalletIdByUID,
    editRoomName,
    editRoomDescription,
    adminListNftSales,
  };
};

export default useRooms;
