import { API_BASE_URL, API_ENDPOINTS, API_GRAPHIQL } from '../../../constants/api';
import { Http } from '../../../services/http';

interface IPostCreateRoom {
  roomname: string;
  roomsize: string;
  roomdescription: string;
  sceneid: string;
  roomadmins: string;
  numberofrooms: string;
  imageurl: string;
}

interface PostAddRoom {
  sceneId: string;
  imageUrl: string;
  type: number;
  ownerId: string;
  accessPass: string;
}

interface IGraphCreateRoom {
  name: string;
  roomSize: string;
  description: string;
  sceneId: string;
}

const useRoomCreate = () => {
  const postCreateRoom = async (body: IPostCreateRoom) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.ADMIN_CREATE_ROOM}`;
    const res = await Http.post(url, body);

    return res;
  };

  const postAddRoom = async (body: PostAddRoom) => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.ADMIN_ASSIGN_SCENE}`;
    const res = await Http.post(url, body);

    if (res?.message.includes('Scene with')) {
      return { err: true };
    }

    return res;
  };

  const graphCreateRoom = async ({ name, roomSize, description, sceneId }: IGraphCreateRoom) => {
    // eslint-disable-next-line max-len
    const query = `mutation {createRoom(name: "${name}",roomSize: ${roomSize}, description: "${description}", sceneId: "${sceneId}"){id, roomSize}}`;
    const url = `${API_GRAPHIQL}`;
    const extra_headers = {
      Authorization: 'Bearer 2fc4PHz.bmhFMFRYMEtMY0VxbFdYT2tQaXpnU3Jq',
    };

    const res = await Http.post(url, { query }, extra_headers);

    return res;
  };

  return { postCreateRoom, postAddRoom, graphCreateRoom };
};

export default useRoomCreate;
