import React from 'react';
import './RegisterPage.scss';
import { useLocation } from 'react-router-dom';
import { initialValuesRegister } from '../../constants/defaultData';
import { Field, Form, Formik } from 'formik';
import Button from '../../components/_common/Button/Button';
import useAuth from '../../hooks/useAuth';
import SVG_ICONS from '../../constants/SVG';

function RegisterPage() {
  const location = useLocation();
  const { registration } = useAuth();
  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get('token');

  const registerForm = () => {
    return (
      <Formik
        initialValues={initialValuesRegister}
        onSubmit={async (values) => {
          if (token) {
            await registration({ ...values, token: token });
          } else {
            console.log(token, 'token');
          }
        }}
      >
        {({ values, handleChange }) => {
          return (
            <Form className={'form-container'}>
              <img src={SVG_ICONS.MAIN_LOGO} alt="logo" />
              <h2>Registration</h2>
              <p>Email</p>
              <Field
                className={'form_input'}
                placeholder={'Email'}
                name={'email'}
                onChange={handleChange('email')}
                values={values.email}
              />
              <p>Password</p>
              <Field
                className={'form_input'}
                placeholder={'Password'}
                name={'Password'}
                onChange={handleChange('password')}
                values={values.password}
              />
              <Button className={'login_button'} title={'Register'} type={'submit'} />
            </Form>
          );
        }}
      </Formik>
    );
  };

  return <div className={'register-page'}>{registerForm()}</div>;
}

export default RegisterPage;
