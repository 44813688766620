import React, { useEffect, useState } from 'react';
import './SpecialUser.scss';
import useUsers from '../../hooks/useUsers';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import getLocalDate from '../../utilsCommon/UtilsCommon';
import Checkbox from '../../components/_common/Checkbox/Checkbox';

import SpecialUserRoomsItem from '../../components/SpecialUserItem/SpecialUserRoomsItem';
import useRooms from '../../hooks/useRooms';
import { MetaProEditor, SceneData } from '../../types/users';
import checkDate from '../../utilsCommon/checkDate';

function SpecialUser() {
  const { getRoomSize } = useRooms();
  const {
    getUserBySearch,
    getRoomsByUserId,
    getEventEnter,
    toggleUnlimitedUserRooms,
    toggleMessageBan,
    toggleBanUser,
    getSingleProAccess,
    getRoomPurchases,
  } = useUsers();
  const { wallet, id } = useParams();
  // const { dateCreated, walletId } = useSelector((state: ApplicationState) => state.users.specialUser);
  const {
    dateCreated,
    walletId,
    rooms,
    active,
    canMessage,
    canUnlimited,
    lastLogin,
    ipAddress,
    browser,
    banReason,
    email,
  } = useSelector((state: ApplicationState) => state.users.specialUser);
  // const { ARENA_ACCESS } = useSelector((state: ApplicationState) => state.users.eventEnter);
  // const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isUserBanned, setIsUserBanned] = useState<boolean>(false);
  const [isMessagingBanned, setIsMessagingBanned] = useState<boolean>(false);
  const [isUnlimited, setIsUnlimited] = useState(false);
  const [isSaved, setIsSaved] = useState({ userBanned: false, messagingBanned: false, unlimited: false });
  const [proAccess, setProAccess] = useState({} as MetaProEditor);
  const [sceneData, setSceneData] = useState<SceneData[]>([]);

  // const handleReset = async () => {
  //   if (wallet) {
  //     await postUserEventReset(wallet);
  //     await getEventEnter(wallet).then(() => setIsChecked(Boolean(ARENA_ACCESS)));
  //   }
  // };

  const handleUnlimitedClick = async () => {
    if (wallet) {
      const { success: isUnlimitedUserRooms } = await toggleUnlimitedUserRooms(wallet);

      if (isUnlimitedUserRooms === 'User Unlimted Rooms Value set to 1') {
        setIsUnlimited(true);
        setIsSaved((prevState) => ({ ...prevState, unlimited: true }));
      } else if (isUnlimitedUserRooms === 'User Unlimted Rooms Value set to 0') {
        setIsUnlimited(false);
        setIsSaved((prevState) => ({ ...prevState, unlimited: true }));
      }

      getUserBySearch({ pagenum: 1, param: wallet });
    }
  };

  const handleBanUser = async () => {
    if (wallet) {
      const { success: isActiveUserString } = await toggleBanUser(wallet);

      if (isActiveUserString === 'User active bool set to 1') {
        setIsUserBanned(false);
        setIsSaved((prevState) => ({ ...prevState, userBanned: true }));
      } else if (isActiveUserString === 'User active bool set to 0') {
        setIsUserBanned(true);
        setIsSaved((prevState) => ({ ...prevState, userBanned: true }));
      }

      getUserBySearch({ pagenum: 1, param: wallet });
    }
  };

  const handleBanMessaging = async () => {
    if (wallet) {
      const { success: canMessageString } = await toggleMessageBan(wallet);

      if (canMessageString === 'User can-message bool set to 1') {
        setIsMessagingBanned(false);
        setIsSaved((prevState) => ({ ...prevState, messagingBanned: true }));
      } else if (canMessageString === 'User can-message bool set to 0') {
        setIsMessagingBanned(true);
        setIsSaved((prevState) => ({ ...prevState, messagingBanned: true }));
      }

      getUserBySearch({ pagenum: 1, param: wallet });
    }
  };

  useEffect(() => {
    if (wallet && id) {
      getUserBySearch({ pagenum: 1, param: wallet });
      getRoomsByUserId({ pagenum: 1, userid: id });
      getEventEnter(wallet);
      getRoomPurchases(wallet).then((infoRoomPurchase) => {
        setSceneData(infoRoomPurchase);
      });
    }
  }, [wallet]);

  // useEffect(() => {
  //   setIsChecked(Boolean(ARENA_ACCESS));
  // }, [ARENA_ACCESS]);

  useEffect(() => {
    getRoomSize();

    if (id) {
      getSingleProAccess(id).then((proUser) => setProAccess(proUser));
    }
  }, []);

  useEffect(() => {
    setIsUserBanned(!Boolean(active));
    setIsMessagingBanned(!Boolean(canMessage));
    setIsUnlimited(Boolean(canUnlimited));
  }, [active, canMessage, canUnlimited]);

  const renderRoomList = () => {
    const openRooms = rooms.filter((room) => checkDate(room.CREATED_DATE));

    return openRooms.map((room, index) => <SpecialUserRoomsItem index={index} key={index} item={room} />);
  };

  return (
    <div className={'special_user'}>
      <div className={'special_user-header'}>
        <div className={'special_user-header-item'}>
          <p className={'special_user-header-item_title'}>Wallet ID/Email</p>
          <p className={'special_user-header-item_info'}>{walletId}</p>
        </div>
        <div className={'special_user-header-item'}>
          <p className={'special_user-header-item_title'}>Last Login</p>
          <p className={'special_user-header-item_info'}>{lastLogin && getLocalDate(lastLogin)}</p>
        </div>
        <div className={'special_user-header-item'}>
          <p className={'special_user-header-item_title'}>Date Created</p>
          <p className={'special_user-header-item_info'}>{dateCreated && getLocalDate(dateCreated)}</p>
        </div>
      </div>
      <div className={'special_user-actions'}>
        {/*<Checkbox*/}
        {/*  className={'special_user-reset'}*/}
        {/*  label={'Reset NFT Access'}*/}
        {/*  btnText={'Accept'}*/}
        {/*  handleClick={handleReset}*/}
        {/*  handleChange={() => setIsChecked(!isChecked)}*/}
        {/*  isDisabled={isChecked === Boolean(ARENA_ACCESS)}*/}
        {/*  isChecked={isChecked}*/}
        {/*  name={'reset'}*/}
        {/*/>*/}
        <Checkbox
          className={'special_user-unlimited'}
          label={'Unlimited Rooms'}
          btnText={'Accept'}
          handleClick={handleUnlimitedClick}
          handleChange={() => setIsUnlimited(!isUnlimited)}
          isDisabled={Boolean(canUnlimited) === isUnlimited}
          isChecked={isUnlimited}
          name={'unlimited'}
          isSaved={isSaved.unlimited}
        />
        <Checkbox
          className={'special_user-ban'}
          label={'BAN USER'}
          btnText={'Accept'}
          handleClick={handleBanUser}
          handleChange={() => setIsUserBanned(!isUserBanned)}
          isDisabled={!Boolean(active) === isUserBanned}
          isChecked={isUserBanned}
          name={'banUser'}
          isSaved={isSaved.userBanned}
        />

        <Checkbox
          className={'special_user-ban-messaging'}
          label={'BAN FROM MESSAGING'}
          btnText={'Accept'}
          handleClick={handleBanMessaging}
          handleChange={() => setIsMessagingBanned(!isMessagingBanned)}
          isDisabled={!Boolean(canMessage) === isMessagingBanned}
          isChecked={isMessagingBanned}
          name={'banMessaging'}
          isSaved={isSaved.messagingBanned}
        />
      </div>

      <div className={'special_user-header'}>
        <div className={'special_user-header-item'}>
          <p className={'special_user-header-item_title'}>IP address</p>
          <p className={'special_user-header-item_info'}>{ipAddress}</p>
        </div>
        <div className={'special_user-header-item'}>
          <p className={'special_user-header-item_title'}>Browser login</p>
          <p className={'special_user-header-item_info'}>{browser}</p>
        </div>
        <div className={'special_user-header-item'}>
          <p className={'special_user-header-item_title'}>Email address</p>
          <p className={'special_user-header-item_info'}>{email}</p>
        </div>
        <div className={'special_user-header-item'}>
          <p className={'special_user-header-item_title'}>User banned reason</p>
          <p className={'special_user-header-item_info'}>{banReason}</p>
        </div>
        <div className={'special_user-header-item'}>
          <p className={'special_user-header-item_title'}>Username</p>
          <p className={'special_user-header-item_info'}>N/A</p>
        </div>
      </div>
      {proAccess.hasOwnProperty('EMAILADDRESS') ? (
        <div className={'special_user-meta-pro-editor'}>
          <h3 className={'special_user_heading'}>meta pro editor</h3>
          <div className={'special_user-header'}>
            <div className={'special_user-header-item'}>
              <p className={'special_user-header-item_title'}>Links</p>
              <p className={'special_user-header-item_info'}>{proAccess.LINKS}</p>
            </div>
            <div className={'special_user-header-item'}>
              <p className={'special_user-header-item_title'}>Email</p>
              <p className={'special_user-header-item_info'}>{proAccess.EMAILADDRESS}</p>
            </div>
            <div className={'special_user-header-item'}>
              <p className={'special_user-header-item_title'}>Reason</p>
              <p className={'special_user-header-item_info'}>{proAccess.REASON}</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={'special_user-content'}>
        <div className={'special_user-content_header'}>
          <p>Room Name</p>
          <p>Room Description</p>
          <p>Status</p>
          <p>Featured</p>
          <p>Trending</p>
          <p>Created Date</p>
          <p>Last Updated</p>
          <p>No. of User</p>
          <p>Room Link</p>
          <p>Room Status</p>
          <p>Suspend Room</p>
          <span />
        </div>
        {renderRoomList().length ? (
          <div className={'special_user-content_wrapper'}>{renderRoomList()}</div>
        ) : (
          <div className={'special_user-content_no-info'}>
            <p>No rooms</p>
          </div>
        )}
      </div>

      <div className="special_user-scene">
        <div className="special_user-scene__head">
          <p>Scene name</p>
          <p>Scene description</p>
          <p>Date/Time of Purchase</p>
          <p>Value Amount</p>
        </div>
        {sceneData.length ? (
          <div className="special_user-scene__body">
            {sceneData.map((info, index) => (
              <div className="special_user-scene__body-item" key={index}>
                <p>{info.sceneName}</p>
                <p>{info.sceneDescription}</p>
                <p>{new Date(info.createdAt).toLocaleDateString()}</p>
                <p>{info.price}</p>
              </div>
            ))}
          </div>
        ) : (
          <div className="special_user-scene_no-info">
            <p>No info</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default SpecialUser;
