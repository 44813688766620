import EMPLOYEE_ACTION_TYPES from '../actionTypes/employeeActionTypes';
import { CurrentEmployee, InvitedEmployee } from '../../types/employee';

export const actionGetInvitedEmployee = (data: InvitedEmployee[]) => {
  return {
    type: EMPLOYEE_ACTION_TYPES.GET_INVITED_EMPLOYEE,
    data,
  };
};

export const actionGetCurrentEmployee = (data: CurrentEmployee[]) => {
  return {
    type: EMPLOYEE_ACTION_TYPES.GET_CURRENT_EMPLOYEE,
    data,
  };
};

export const actionRevokeInvite = (data: string) => {
  console.log(data, 'token');

  return {
    type: EMPLOYEE_ACTION_TYPES.REVOKE_INVITE,
    data,
  };
};
