import React, { useState } from 'react';
import './ResetPassword.scss';
import SVG_ICONS from '../../constants/SVG';
import { Field, Form, Formik } from 'formik';
import Button from '../../components/_common/Button/Button';
import useAuth from '../../hooks/useAuth';
// import { useNavigate } from 'react-router-dom';
import Modal from '../../components/_common/Modal/Modal';

function ResetPassword() {
  // const navigate = useNavigate();
  const { sendResetPasswordLink } = useAuth();
  const [isSend, setIsSend] = useState<boolean>(false);

  return (
    <div className={'reset_password'}>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={async (values) => {
          const data = await sendResetPasswordLink(values.email);

          if (!data.error) {
            setIsSend(true);
          }
        }}
      >
        {({ values, handleChange }) => {
          return (
            <Form className={'reset_password-form'}>
              <img src={SVG_ICONS.MAIN_LOGO} alt="main logo" />
              <p>Send reset link</p>
              <Field
                className={'form_input'}
                name={'email'}
                value={values.email}
                onChange={handleChange('email')}
                placeholder={'Enter your email'}
              />
              <Button className={'login_button'} type={'submit'} title={'Send Link'} />
              {isSend && (
                <Modal open={isSend} onClose={() => setIsSend(false)}>
                  <p>Email has been sent.</p>
                </Modal>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ResetPassword;
