import { ActionInterface } from '../../types/common';
import { usersReducerDefaultState } from '../../constants/defaultData';
import USER_ACTION_TYPES from '../actionTypes/usersActionTypes';
import { EventEnter, MetaProEditor, NFTsByUserRoom, SpecialUserRoomsItem, User } from '../../types/users';

export interface UsersReducer {
  dataList: User[];
  specialUser: {
    walletId: string;
    lastLogin: Date | null;
    dateCreated: Date | null;
    rooms: SpecialUserRoomsItem[];
    currentRoomInfo: NFTsByUserRoom[];
    showDetails: number;
    active: number;
    canMessage: number;
    canUnlimited: number;
    ipAddress: string;
    browser: string;
    banReason: string | null;
    email: string | null;
  };
  eventEnter: EventEnter;
  proAccess: MetaProEditor[];
}

function userReducer(state: UsersReducer = usersReducerDefaultState, { data, type }: ActionInterface) {
  switch (type) {
    case USER_ACTION_TYPES.GET_DASHBOARD_USERS: {
      return { ...state, dataList: data };
    }

    case USER_ACTION_TYPES.GET_DASHBOARD_USER_BY_SEARCH: {
      return {
        ...state,
        specialUser: {
          ...state.specialUser,
          walletId: data[0].WALLETID,
          dateCreated: data[0].CREATED_DATE,
          lastLogin: data[0].LAST_LOGIN,
          active: data[0].IS_ACTIVE,
          canMessage: data[0].CAN_MESSAGE,
          canUnlimited: data[0].CAN_UNLIMITED_ROOMS,
          ipAddress: data[0].LAST_LOGIN_IP,
          browser: data[0].LAST_LOGIN_BROWSER,
          banReason: data[0].BAN_REASON,
          email: data[0].EMAIL,
        },
      };
    }

    case USER_ACTION_TYPES.GET_DASHBOARD_USER_ROOMS: {
      return {
        ...state,
        specialUser: { ...state.specialUser, rooms: data },
      };
    }

    case USER_ACTION_TYPES.SHOW_USER_NFTs_INFO: {
      return {
        ...state,
        specialUser: { ...state.specialUser, showDetails: data },
      };
    }

    case USER_ACTION_TYPES.GET_NFT_DETAILS: {
      return {
        ...state,
        specialUser: { ...state.specialUser, currentRoomInfo: data },
      };
    }

    case USER_ACTION_TYPES.FIND_USER: {
      return {
        ...state,
        dataList: data,
      };
    }

    case USER_ACTION_TYPES.GET_META_PRO_REQUEST: {
      return {
        ...state,
        proAccess: data,
      };
    }

    case USER_ACTION_TYPES.GET_EVENT_ENTER_INFO: {
      return {
        ...state,
        eventEnter: data,
      };
    }
  }

  return state;
}

export default userReducer;
