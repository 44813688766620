import React, { useEffect, useState } from 'react';
import './EmployeePage.scss';
import useEmployee from '../../hooks/useEmployee';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import EmployeeItem from '../../components/EmployeeItem/EmployeeItem';
import SendInvite from '../../components/SendInvite/SendInvite';
import Tabs from '../../components/Tabs/Tabs';
import { employeeTabs } from '../../constants/defaultData';

function EmployeePage() {
  const { getInvitedEmployee, getCurrentEmployee, cancelEmployeeInvite, sendRegisterLink } = useEmployee();
  const { currentEmployee, invitedEmployee } = useSelector((state: ApplicationState) => state.employee);
  const [currentTab, setCurrentTab] = useState<number>(0);

  useEffect(() => {
    getInvitedEmployee({ pagenum: 1 });
    getCurrentEmployee({ pagenum: 1 });
  }, []);

  const renderCurrentTab = () => {
    const renderCurrentEmployee = () =>
      currentEmployee &&
      currentEmployee.map(({ EMAIL, CREATED_DATE }, index) => {
        return <EmployeeItem email={EMAIL} date={CREATED_DATE} key={index} />;
      });
    const renderInvitedEmployee = () =>
      invitedEmployee &&
      invitedEmployee.map(({ CREATED_DATE, RECIPIENT, TOKEN }, index) => {
        return (
          <EmployeeItem
            clickHandler={() => cancelEmployeeInvite(TOKEN)}
            email={RECIPIENT}
            date={CREATED_DATE}
            token={TOKEN}
            key={index}
          />
        );
      });

    switch (currentTab) {
      case 0: {
        return (
          <>
            <div className={'employee-page_header_current'}>
              <p>Email Address</p>
              <p>Last Login</p>
            </div>
            {renderCurrentEmployee()}
          </>
        );
      }

      case 1: {
        return (
          <>
            <div className={'employee-page_header_invited'}>
              <p>Email Address</p>
              <p>Invited Users</p>
              <p>Revoke Access</p>
            </div>
            {renderInvitedEmployee()}
          </>
        );
      }

      case 2: {
        return <SendInvite sendLinkHandler={sendRegisterLink} />;
      }
    }
  };

  return (
    <div className={'employee-page'}>
      <Tabs tabs={employeeTabs} changeTabHandler={setCurrentTab} currentTab={currentTab} />
      {renderCurrentTab()}
    </div>
  );
}

export default EmployeePage;
