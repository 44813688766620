const USER_ACTION_TYPES = {
  GET_DASHBOARD_USERS: 'GET_DASHBOARD_USERS',
  GET_DASHBOARD_USER_BY_SEARCH: 'GET_DASHBOARD_USER_BY_SEARCH',
  GET_DASHBOARD_USER_ROOMS: 'GET_DASHBOARD_USER_ROOMS',
  SHOW_USER_NFTs_INFO: 'SHOW_USER_NFTs_INFO',
  FIND_USER: 'FIND_USER',
  GET_NFT_DETAILS: 'GET_NFT_DETAILS',
  GET_EVENT_ENTER_INFO: 'GET_EVENT_ENTER_INFO',
  GET_META_PRO_REQUEST: 'GET_META_PRO_REQUEST',
};

export default USER_ACTION_TYPES;
