import { ActionInterface } from '../../types/common';
import { chartReducerDefaultState } from '../../constants/defaultData';
import CHARTS_ACTION_TYPES from '../actionTypes/chartsActionTypes';
import {
  ChartAccessPassSearch24,
  ChartPlacedNFT,
  ChartsData,
  ChartSoldNFTData,
  ChartAccessPassSearchMonth,
  ChartAccessPassSearchYear,
  ChartTotalAccessPassSearch,
  ChartFreeTrailUsersSearch,
  ChartDailyUniqueUsers,
  ChartMonthlyUniqueUsers,
  ChartAdminRoomVisits,
  ChartAdminNftSoldSum,
  ChartAdminNftSoldCount,
  ChartAdminNftClicks,
  ChartAdminMostRevenueRooms,
  ChartAdminMostClickedNft,
  ChartAccessPassSearchLegend,
  ChartAdminNftSoldSumPerDay,
} from '../../types/charts';

export interface ChartReducer {
  usersCreated: ChartsData[];
  roomsCreated: ChartsData[];
  nftPlaced: ChartPlacedNFT[];
  nftSold: ChartSoldNFTData[];
  accessPassSearch24: ChartAccessPassSearch24[];
  accessPassSearchMonth: ChartAccessPassSearchMonth[];
  accessPassSearchYear: ChartAccessPassSearchYear[];
  totalAccessPassSearch: ChartTotalAccessPassSearch[];
  freeTrailUsersSearch: ChartFreeTrailUsersSearch[];
  dailyUniqueUsers: ChartDailyUniqueUsers[];
  monthlyUniqueUsers: ChartMonthlyUniqueUsers[];
  adminRoomVisits: ChartAdminRoomVisits[];
  adminNftSoldSum: ChartAdminNftSoldSum[];
  adminNftSoldCount: ChartAdminNftSoldCount[];
  adminNftClicks: ChartAdminNftClicks[];
  adminMostRevenueRooms: ChartAdminMostRevenueRooms[];
  adminMostClickedNft: ChartAdminMostClickedNft[];
  accessPassSearchLegend: ChartAccessPassSearchLegend[];
  adminNftSoldSumPerDay: ChartAdminNftSoldSumPerDay[];
}

function chartReducer(state: ChartReducer = chartReducerDefaultState, { data, type }: ActionInterface) {
  switch (type) {
    case CHARTS_ACTION_TYPES.GET_NUMBER_OF_USERS_CREATED: {
      return { ...state, usersCreated: data };
    }

    case CHARTS_ACTION_TYPES.GET_NUMBER_OF_ROOMS_CREATED: {
      return { ...state, roomsCreated: data };
    }

    case CHARTS_ACTION_TYPES.GET_NFT_SOLD: {
      return { ...state, nftSold: data };
    }

    case CHARTS_ACTION_TYPES.GET_NUMBER_OF_PLACED_NFT: {
      return { ...state, nftPlaced: data };
    }

    case CHARTS_ACTION_TYPES.GET_ACCESS_PASS_SEARCH_24: {
      return { ...state, accessPassSearch24: data };
    }

    case CHARTS_ACTION_TYPES.GET_ACCESS_PASS_SEARCH_MONTH: {
      return { ...state, accessPassSearchMonth: data };
    }

    case CHARTS_ACTION_TYPES.GET_ACCESS_PASS_SEARCH_YEAR: {
      return { ...state, accessPassSearchYear: data };
    }

    case CHARTS_ACTION_TYPES.GET_TOTAL_ACCESS_PASS_SEARCH: {
      return { ...state, totalAccessPassSearch: data };
    }

    case CHARTS_ACTION_TYPES.GET_FREE_TRAIL_USERS_SEARCH: {
      return { ...state, freeTrailUsersSearch: data };
    }

    case CHARTS_ACTION_TYPES.GET_DAILY_UNIQUE_USERS: {
      return { ...state, dailyUniqueUsers: data };
    }

    case CHARTS_ACTION_TYPES.GET_MONTHLY_UNIQUE_USERS: {
      return { ...state, monthlyUniqueUsers: data };
    }

    case CHARTS_ACTION_TYPES.GET_ADMIN_ROOM_VISITS: {
      return { ...state, adminRoomVisits: data };
    }

    case CHARTS_ACTION_TYPES.GET_ADMIN_NFT_SOLD_SUM: {
      return { ...state, adminNftSoldSum: data };
    }

    case CHARTS_ACTION_TYPES.GET_ADMIN_NFT_SOLD_COUNT: {
      return { ...state, adminNftSoldCount: data };
    }

    case CHARTS_ACTION_TYPES.GET_ADMIN_NFT_CLICKS: {
      return { ...state, adminNftClicks: data };
    }

    case CHARTS_ACTION_TYPES.GET_ADMIN_MOST_REVENUE_ROOMS: {
      return { ...state, adminMostRevenueRooms: data };
    }

    case CHARTS_ACTION_TYPES.GET_ADMIN_MOST_CLICKED_NFT: {
      return { ...state, adminMostClickedNft: data };
    }

    case CHARTS_ACTION_TYPES.GET_ACCESS_PASS_SEARCH_LEGEND: {
      return { ...state, accessPassSearchLegend: data };
    }

    case CHARTS_ACTION_TYPES.GET_ADMIN_NFT_SOLD_SUM_PER_DAY: {
      return { ...state, adminNftSoldSumPerDay: data };
    }
  }

  return state;
}

export default chartReducer;
