import { EventEnter, MetaProEditor, NFTsByUserRoom, User } from '../../types/users';
import USER_ACTION_TYPES from '../actionTypes/usersActionTypes';
import { Room } from '../../types/rooms';

export const actionGetUsers = (data: User[]) => {
  return {
    type: USER_ACTION_TYPES.GET_DASHBOARD_USERS,
    data,
  };
};

export const actionGetUserBySearch = (data: User[]) => {
  return {
    type: USER_ACTION_TYPES.GET_DASHBOARD_USER_BY_SEARCH,
    data,
  };
};

export const actionFindUser = (data: User[]) => {
  return {
    type: USER_ACTION_TYPES.FIND_USER,
    data,
  };
};

export const actionGetUserRooms = (data: Room[]) => {
  return {
    type: USER_ACTION_TYPES.GET_DASHBOARD_USER_ROOMS,
    data,
  };
};

export const actionShowNFTsDetails = (data: number) => {
  return {
    type: USER_ACTION_TYPES.SHOW_USER_NFTs_INFO,
    data,
  };
};

export const actionGetNFTsInfoByRoom = (data: NFTsByUserRoom[]) => {
  return {
    type: USER_ACTION_TYPES.GET_NFT_DETAILS,
    data,
  };
};

export const actionGetEventEnterInfo = (data: EventEnter) => {
  return {
    type: USER_ACTION_TYPES.GET_EVENT_ENTER_INFO,
    data,
  };
};

export const actionGetMetaProRequest = (data: MetaProEditor) => {
  return {
    type: USER_ACTION_TYPES.GET_META_PRO_REQUEST,
    data,
  };
};
