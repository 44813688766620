import React from 'react';
import './Tabs.scss';

interface Props {
  tabs: string[];
  changeTabHandler: (index: number) => void;
  currentTab: number;
}
function Tabs({ tabs, changeTabHandler, currentTab }: Props) {
  const renderTabs = () =>
    tabs.map((tab, index) => {
      return (
        <p
          key={index}
          className={currentTab === index ? 'active' : 'employee_header-tab'}
          onClick={() => changeTabHandler(index)}
        >
          {tab}
        </p>
      );
    });

  return <>{tabs.length > 1 && <div className={'employee_header'}>{renderTabs()}</div>}</>;
}

export default Tabs;
