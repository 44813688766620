import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { initialCreateRoom } from '../../constants/defaultData';
import useRoomCreate from './hooks/useRoomCreate';
import './RoomCreate.scss';

type RoomCreateProps = {
  handleSubmit: () => void;
};

const sceneIds = {
  spaces: 'RWDaT5V',
  businessCentre: 'jHPuoAK',
  floatingOffice: 'xGTPc5H',
  gamer: 'oKYbPbg',
};

const sceneNames = {
  spaces: 'Spaces',
  businessCentre: 'Business Centre',
  floatingOffice: 'Floating Office',
  gamer: 'Gamer',
};

const sceneUrls = {
  spaces: '/static/media/spaces.7316210e40e2b60931ec.webp',
  businessCentre: '/static/media/theatre.c983a4cd0d116fb33491.webp',
  floatingOffice: '/static/media/floating.abf1d351cbd93fdc72aa.webp',
  gamer: '/static/media/gaming.b311aff64d42e7b49ab0.webp',
};

const validationSchema = Yup.object({
  packageType: Yup.string().required('Required!'),
  sceneId: Yup.string().required('Required!'),
  sceneName: Yup.string().when('packageType', {
    is: 'custom',
    then: (validationSchema) => validationSchema.required('Required!'),
  }),
  sceneImageUrl: Yup.string().when('packageType', {
    is: 'custom',
    then: (validationSchema) => validationSchema.max(255, 'Max length must be under 255!').required(''),
  }),
  userWalletIdOrEmail: Yup.string().required('Required!'),
  walletIdEmailAccessPass: Yup.string().required('Required!'),
});

const RoomCreate = ({ handleSubmit }: RoomCreateProps) => {
  const { postAddRoom } = useRoomCreate();

  return (
    <div className={'room-create'}>
      <Formik
        initialValues={initialCreateRoom}
        onSubmit={async (values, actions) => {
          const { sceneId, sceneName, sceneImageUrl, userWalletIdOrEmail, packageType, walletIdEmailAccessPass } =
            values;
          const body = {
            sceneId: `${sceneIds[`${sceneId}` as keyof typeof sceneIds] || sceneId}`,
            sceneName: `${sceneName ? sceneName : sceneNames[`${sceneId}` as keyof typeof sceneUrls]}`,
            imageUrl: `${sceneImageUrl ? sceneImageUrl : sceneUrls[`${sceneId}` as keyof typeof sceneUrls]}`,
            type: packageType === 'starter' ? 2 : 4,
            ownerId: userWalletIdOrEmail,
            accessPass: walletIdEmailAccessPass,
          };

          const res = await postAddRoom(body);

          if (res.err) {
            actions.setFieldError('error', 'Scene already assigned to the user');
          } else {
            handleSubmit();
          }
        }}
        validationSchema={validationSchema}
      >
        {({ dirty, isValid, values }) => (
          <Form className={'room-create_form'}>
            <Field className={'room-create_input company select'} name="packageType" as="select" autoComplete="off">
              <option value="" disabled hidden>
                {values.packageType ? values.packageType : 'Company'}
              </option>
              <option value="starter">Starter</option>
              <option value="custom">Custom</option>
            </Field>
            {values.packageType === 'starter' && (
              <>
                <Field
                  className={'room-create_input wallet-id-or-email'}
                  type="text"
                  name={'userWalletIdOrEmail'}
                  placeholder={'Enter User WalletId or Email'}
                  autoComplete="off"
                />
                <Field className={'room-create_input company select'} as="select" name="sceneId" autoComplete="off">
                  <option value="" disabled hidden>
                    {values.sceneId || 'Enter Spoke Scene ID'}
                  </option>
                  <option value="spaces">Spaces</option>
                  <option value="businessCentre">Business Centre</option>
                  <option value="floatingOffice">Floating Office</option>
                  <option value="gamer">Gamer</option>
                </Field>
                <Field
                  className={'room-create_input wallet-id-or-email'}
                  type="text"
                  name={'walletIdEmailAccessPass'}
                  placeholder={'Enter Wallet ID/Email for Access Pass User'}
                  autoComplete="off"
                />
              </>
            )}
            {values.packageType === 'custom' && (
              <>
                <Field
                  className={'room-create_input scene-id'}
                  type="text"
                  name={'sceneId'}
                  placeholder={'Enter Spoke Scene ID'}
                  autoComplete="off"
                />
                <Field
                  className={'room-create_input scene-name'}
                  type="text"
                  name={'sceneName'}
                  placeholder={'Enter Scene Name'}
                  autoComplete="off"
                />
                <Field
                  className={'room-create_input scene-image-url'}
                  type="text"
                  name={'sceneImageUrl'}
                  placeholder={'Enter Scene Image Url'}
                  autoComplete="off"
                />
                <ErrorMessage name={'sceneImageUrl'} component={'p'} className={'room-create_error'} />
                <Field
                  className={'room-create_input wallet-id-or-email'}
                  type="text"
                  name={'userWalletIdOrEmail'}
                  placeholder={'Enter User WalletId or Email'}
                  autoComplete="off"
                />
                <Field
                  className={'room-create_input wallet-id-or-email'}
                  type="text"
                  name={'walletIdEmailAccessPass'}
                  placeholder={'Enter Wallet ID/Email for Access Pass User'}
                  autoComplete="off"
                />
              </>
            )}
            {values.packageType !== '' && (
              <>
                <ErrorMessage name={'error'} component={'p'} className={'room-create_error'} />
                <button type={'submit'} className={'room-create_btn'} disabled={!(dirty && isValid)}>
                  Create Room
                </button>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RoomCreate;
