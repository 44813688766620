import React from 'react';
import './Search.scss';

type SearchProps = {
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClick: () => void;
  placeholder: string;
  btnText: string;
  className?: string;
};

const Search = ({ value, handleChange, handleClick, placeholder, btnText, className = '' }: SearchProps) => {
  return (
    <div className={`search ${className}`}>
      <input className={'search_input'} type={'text'} value={value} onChange={handleChange} placeholder={placeholder} />
      <button type="button" className={'search_btn'} onClick={handleClick}>
        {btnText}
      </button>
    </div>
  );
};

export default Search;
