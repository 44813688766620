import React, { useEffect, useState } from 'react';
import './RoomsPage.scss';
import PagesHeader from '../../components/PagesHeader/PagesHeader';
import useRooms from '../../hooks/useRooms';
import RoomsItem from '../../components/RoomsItem/RoomsItem';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import Tabs from '../../components/Tabs/Tabs';
import { roomsTabs } from '../../constants/defaultData';
import Search from '../../components/_common/Search/Search';
import RoomCreate from '../../components/RoomCreate/RoomCreate';

function RoomsPage() {
  const { getRooms, getFeaturedRooms, getTrendingRooms, switchFeatureRoom, switchTrendRoom } = useRooms();
  const { dataList } = useSelector((state: ApplicationState) => state.rooms);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [search, setSearch] = useState('');
  const [isRoomCreate, setIsRoomCreate] = useState(false);

  const handleFeatureSearchClick = async () => {
    if (search) {
      await switchFeatureRoom(search);
      setSearch('');
      getFeaturedRooms();
    }
  };

  const handleTrendSearchClick = async () => {
    if (search) {
      await switchTrendRoom(search);
      setSearch('');
      getTrendingRooms();
    }
  };

  useEffect(() => {
    switch (currentTab) {
      case 0:
        getRooms({ pagenum: 1 });

        return;
      case 1:
        getFeaturedRooms();

        return;
      case 2:
        getTrendingRooms();

        return;
      default:
        getRooms({ pagenum: 1 });
    }
  }, [currentTab]);

  const renderRoomList = (type?: string) =>
    dataList.map((room, index) => {
      return <RoomsItem key={index} item={room} type={type || ''} />;
    });

  return (
    <div className={'rooms-page'}>
      <Tabs tabs={roomsTabs} changeTabHandler={setCurrentTab} currentTab={currentTab} />
      {currentTab === 0 && !isRoomCreate && (
        <>
          <PagesHeader btnText="Add New Room" handleClick={() => setIsRoomCreate(true)} />
          <div className={'rooms-page_header'}>
            <p>Room Name </p>
            <p>Room Description </p>
            <p>Room Owner </p>
            <p>Link</p>
            <p>Date Created </p>
            <p>Last Updated</p>

            <span />
          </div>
          {renderRoomList()}
        </>
      )}
      {currentTab === 0 && isRoomCreate && <RoomCreate handleSubmit={() => setIsRoomCreate(false)} />}
      {currentTab === 1 && (
        <>
          <div className={'rooms-page_header'}>
            <p>Room Name </p>
            <p>Room Description </p>
            <p>Room Owner </p>
            <p>Link</p>
            <p>Date Featured</p>
            <p>Unfeature</p>

            <span />
          </div>
          {renderRoomList('featured')}
          <Search
            value={search}
            handleChange={(e) => setSearch(e.target.value)}
            handleClick={handleFeatureSearchClick}
            placeholder={'Feature Room - Enter Room ID'}
            btnText={'Feature'}
            className={'rooms-page_search'}
          />
        </>
      )}

      {currentTab === 2 && (
        <>
          <div className={'rooms-page_header'}>
            <p>Room Name </p>
            <p>Room Description </p>
            <p>Room Owner </p>
            <p>Link</p>
            <p>Date Trended</p>
            <p>Untrend</p>

            <span />
          </div>

          {renderRoomList('trend')}
          <Search
            value={search}
            handleChange={(e) => setSearch(e.target.value)}
            handleClick={handleTrendSearchClick}
            placeholder={'Trend Room - Enter Room ID'}
            btnText={'Trend'}
            className={'rooms-page_search'}
          />
        </>
      )}

      {currentTab === 3 && (
        <>
          <PagesHeader />
          <div className={'rooms-page_header'}>
            <p>Room Name </p>
            <p>Room Description </p>
            <p>Room Owner </p>
            <p>Link</p>
            <p>Date Created </p>
            <p>Last Updated</p>
            <span />
          </div>
          {renderRoomList()}
        </>
      )}
    </div>
  );
}

export default RoomsPage;
