import React, { useEffect, useMemo, useState } from 'react';
import './SpecificRoom.scss';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { lockType, roomType, specificRoomItems } from '../../constants/defaultData';
import getLocalDate from '../../utilsCommon/UtilsCommon';
import useRooms from '../../hooks/useRooms';
import { useParams } from 'react-router-dom';
import Checkbox from '../../components/_common/Checkbox/Checkbox';
// import useCountDownTimer from '../../hooks/useCountDownTimer';
import { Room } from '../../types/rooms';
import SpecificRoomPage from '../SpecificRoomPage/SpecificRoomPage';
import CustomInput from '../../components/_common/CustomInput/CustomInput';
import { IListNftSales } from '../../types/users';

function SpecificRoom() {
  const {
    getSpecificRoomInfo,
    getRoomNFTs,
    switchSuspendRoom,
    switchFeatureRoom,
    switchTrendRoom,
    switchUnlimitedSpecificRoom,
    getRoomSize,
    postRoomSize,
    getFeaturedRooms,
    getTrendingRooms,
    getWalletIdByUID,
    adminListNftSales,
  } = useRooms();

  const { name } = useParams();
  const { ROOM_NAME, ROOM_DESCRIPTION, CREATED_DATE, LOCK_TYPE, ROOM_TYPE, ROOM_ID, ISSUSPENDED, ISUNLIMITEDACCESS } =
    useSelector((state: ApplicationState) => state.rooms.specificRoom);
  const { currentRoomNFTs } = useSelector((state: ApplicationState) => state.rooms);
  const roomsGraphql = useSelector((state: ApplicationState) => state.rooms.specificRoomSize);
  const [isSuspend, setIsSuspend] = useState(Boolean(ISSUSPENDED));
  const [isFeature, setIsFeature] = useState(false);
  const [isUnlimited, setIsUnlimited] = useState(false);
  const [roomSize, setRoomSize] = useState('');
  const [roomSizeError, setRoomSizeError] = useState('');
  // const { countDownValue } = useCountDownTimer(CREATED_DATE!);
  const [walletId, setWalletId] = useState('');
  const [isSaved, setIsSaved] = useState({
    suspend: false,
    trend: false,
    feature: false,
    roomSize: false,
    unlimited: false,
  });
  const [isTrending, setIsTrending] = useState(false);
  const [listNftsSales, setListNftsSales] = useState<IListNftSales[] | []>([]);

  const handleSuspendClick = async () => {
    const res = await switchSuspendRoom(ROOM_ID);

    if (res.success === 'Room suspension is now set to 1') {
      setIsSuspend(true);
      setIsSaved((prevState) => ({ ...prevState, suspend: true }));
      getSpecificRoomInfo({
        pagenum: 1,
        param: name!,
      });
    } else if (res.success === 'Room suspension is now set to 0') {
      setIsSuspend(false);
      setIsSaved((prevState) => ({ ...prevState, suspend: true }));
      getSpecificRoomInfo({
        pagenum: 1,
        param: name!,
      });
    }
  };

  const handleTrendingClick = async () => {
    const res = await switchTrendRoom(ROOM_ID);

    if (res.success === 'Room has been added to trending rooms') {
      setIsTrending(true);
      setIsSaved((prevState) => ({ ...prevState, trend: true }));
    } else {
      setIsTrending(false);
      setIsSaved((prevState) => ({ ...prevState, trend: true }));
    }
  };

  const handleFeatureClick = async () => {
    const res = await switchFeatureRoom(ROOM_ID);

    if (res.success === 'Room has been added to featured rooms') {
      setIsFeature(true);
      setIsSaved((prevState) => ({ ...prevState, feature: true }));
    } else if (res.success === 'Room has been unfeatured') {
      setIsFeature(false);
      setIsSaved((prevState) => ({ ...prevState, feature: true }));
    }
  };

  const handleUnlimitedClick = async () => {
    const res = await switchUnlimitedSpecificRoom(ROOM_ID);

    if (res.success === 'User Unlimted Rooms Value set to 1') {
      setIsUnlimited(true);
      setIsSaved((prevState) => ({ ...prevState, unlimited: true }));
      getSpecificRoomInfo({
        pagenum: 1,
        param: name!,
      });
    } else if (res.success === 'User Unlimted Rooms Value set to 0') {
      setIsUnlimited(false);
      setIsSaved((prevState) => ({ ...prevState, unlimited: true }));
      getSpecificRoomInfo({
        pagenum: 1,
        param: name!,
      });
    }
  };

  const handlePostRoomSize = async () => {
    setRoomSizeError('');
    const res = await postRoomSize(ROOM_ID, Number(roomSize));

    if (res.error) {
      setIsSaved((prevState) => ({ ...prevState, roomSize: false }));
      const error = res.error;

      setRoomSizeError(error);
    } else {
      setIsSaved((prevState) => ({ ...prevState, roomSize: true }));
    }
  };

  useEffect(() => {
    if (name) {
      getSpecificRoomInfo({
        pagenum: 1,
        param: name,
      }).then((roomInfo) => {
        if (roomInfo.length) {
          const uid = roomInfo[0].UID;

          getWalletIdByUID(uid).then(({ WALLETID }) => {
            if (WALLETID) {
              setWalletId(WALLETID);
            } else {
              setWalletId('-');
            }
          });
        }
      });

      getRoomNFTs(name);

      adminListNftSales(name).then((nftsInfo) => {
        setListNftsSales(nftsInfo);
      });
    }

    getRoomSize();
  }, []);

  useEffect(() => {
    getFeaturedRooms().then((rooms) => {
      const featuredRoom = rooms.find((room: Room) => room.ROOM_ID === ROOM_ID);

      if (featuredRoom) {
        setIsFeature(true);
      }
    });

    getTrendingRooms().then((rooms) => {
      const trendingRoom = rooms.find((room: Room) => room.ROOM_ID === ROOM_ID);

      if (trendingRoom) {
        setIsTrending(true);
      }
    });
  }, [ROOM_ID]);

  useEffect(() => {
    setIsSuspend(Boolean(ISSUSPENDED));
    setIsUnlimited(Boolean(ISUNLIMITEDACCESS));
  }, [ISSUSPENDED, ISUNLIMITEDACCESS]);

  const roomGraphql = roomsGraphql.find((room) => room.id === ROOM_ID);
  const members = roomGraphql && roomGraphql.memberCount;

  // const renderRoomStatus = useMemo(() => {
  //   return countDownValue === 0 ? 'Closed' : ISUNLIMITEDACCESS ? 'Unlimited' : getFormatTimeValue(countDownValue);
  // }, [ISUNLIMITEDACCESS, countDownValue]);

  useEffect(() => {
    const roomsize = roomGraphql && roomGraphql.roomSize;

    setRoomSize(String(roomsize));
  }, [roomGraphql]);

  const currentInfo = [
    ROOM_NAME,
    LOCK_TYPE,
    `https://metaverse.bluemoon.io/${ROOM_ID}`,
    ROOM_DESCRIPTION,
    CREATED_DATE,
    ROOM_TYPE,
    walletId,
    ROOM_ID,
    members,
  ];

  const renderRoomInfo = useMemo(
    () =>
      specificRoomItems.map((item, index) => {
        if (item === 'Date Created') {
          return (
            <div key={index} className={'specific_room-info_item'}>
              <label className={'title'}>{item}</label>
              <input className={'info'} type="text" value={getLocalDate(CREATED_DATE as Date)} disabled />
            </div>
          );
        } else if (item === 'Room Status') {
          return (
            <div key={index} className={'specific_room-info_item'}>
              <label className={'title'}>{item}</label>
              <input
                className={'info'}
                type="text"
                value={LOCK_TYPE ? `Open - ${lockType[LOCK_TYPE]}` : 'Closed'}
                disabled
              />
            </div>
          );
        } else if (item === 'Room Type') {
          return (
            <div key={index} className={'specific_room-info_item'}>
              <label className={'title'}>{item}</label>
              <input className={'info'} type="text" value={ROOM_TYPE ? roomType[ROOM_TYPE] : ''} disabled />
            </div>
          );
        } else {
          return (
            <div key={index} className={'specific_room-info_item'}>
              {index === 0 || index === 3 ? (
                <div>
                  <label className={'title'}>{item}</label>
                  <CustomInput index={index} id={ROOM_ID} value={currentInfo[index]?.toString()} />
                </div>
              ) : (
                <>
                  <label className={'title'}>{item}</label>
                  <input className={'info'} type="text" value={currentInfo[index]?.toString()} disabled />
                </>
              )}
            </div>
          );
        }
      }),
    currentInfo
  );

  const listNFTs = currentRoomNFTs.filter(({ NAME }) => Boolean(NAME));
  const listImages = currentRoomNFTs.filter(({ NAME }) => !Boolean(NAME));

  const renderNFTsInfo = () =>
    listNFTs.map(({ DESCRIPTION, NAME, ACTION_DATE }, key) => {
      return (
        <div className={'nft-info-item'} key={key}>
          <p>{NAME ?? '-'}</p>
          <p>{DESCRIPTION ?? '-'}</p>
          <p>{getLocalDate(ACTION_DATE)}</p>
        </div>
      );
    });

  const renderImages = () =>
    listImages.map(({ ASSETSTORAGEREF, ACTION_DATE }, key) => {
      return (
        <div className={'nft-info-item'} key={key}>
          <p>{ASSETSTORAGEREF}</p>
          <p></p>
          <p>{getLocalDate(ACTION_DATE)}</p>
        </div>
      );
    });

  return (
    <div className={'specific_room'}>
      <div className={'specific_room-info'}>{renderRoomInfo}</div>
      <div className="specific_room-actions">
        <Checkbox
          label={'Suspend Room'}
          btnText={'Accept'}
          handleClick={handleSuspendClick}
          handleChange={() => setIsSuspend(!isSuspend)}
          isDisabled={Boolean(ISSUSPENDED) === isSuspend}
          isChecked={isSuspend}
          name={'suspend'}
          isSaved={isSaved.suspend}
        />

        <Checkbox
          label={'Trend Room'}
          btnText={'Accept'}
          handleClick={handleTrendingClick}
          handleChange={() => setIsTrending(!isTrending)}
          isDisabled={false}
          isChecked={isTrending}
          name={'trend'}
          isSaved={isSaved.trend}
        />

        <Checkbox
          label={'Feature Room'}
          btnText={'Accept'}
          handleClick={handleFeatureClick}
          handleChange={() => setIsFeature(!isFeature)}
          isDisabled={false}
          isChecked={isFeature}
          name={'feature'}
          isSaved={isSaved.feature}
        />

        <div className={'specific_room-input'}>
          <div className={'specific_room-wrapper'}>
            {isSaved.roomSize && <span className={'specific_room-input-save'}>(saved)</span>}
            <label className={'specific_room-input-label'} htmlFor={'roomSize'}>
              Room Size
            </label>
            <input
              type={'text'}
              className={'specific_room-input-field'}
              name={'roomSize'}
              id={'roomSize'}
              value={roomSize}
              onChange={(e) => setRoomSize(e.target.value)}
            />
            {roomSizeError && <p className={'specific_room-error'}>{roomSizeError}</p>}
          </div>
          <button
            type={'button'}
            className={'specific_room-input-button'}
            onClick={handlePostRoomSize}
            disabled={!roomSize.length}
          >
            Set
          </button>
        </div>

        <Checkbox
          label={'Unlimited Room'}
          btnText={'Accept'}
          handleClick={handleUnlimitedClick}
          handleChange={() => setIsUnlimited(!isUnlimited)}
          isDisabled={Boolean(ISUNLIMITEDACCESS) === isUnlimited}
          isChecked={isUnlimited}
          name={'unlimited'}
          isSaved={isSaved.unlimited}
        />
      </div>
      <div className={'specific_room-assets'}>
        <div className={'specific_room-assets_header'}>
          <p>NFT Name</p>
          <p>NFT Description</p>
          <p>Date Placed</p>
        </div>
        {listNFTs.length ? (
          renderNFTsInfo()
        ) : (
          <div className={'specific_room-assets_no-info'}>
            <p>No info</p>
          </div>
        )}
      </div>
      <div className={'table'}>
        <div className={'specific_room-assets_header'}>
          <p>Model URL</p>
          <p></p>
          <p>Date Placed</p>
        </div>
        {listImages.length ? (
          renderImages()
        ) : (
          <div className={'specific_room-assets_no-info'}>
            <p>No info</p>
          </div>
        )}
      </div>
      <h3 className={'specific_room-analytics-title'}>Sales Analytics</h3>
      <div className={'table'}>
        <div className={'specific_room-assets_header table-header'}>
          <p>NFT Name</p>
          <p>NFT Description</p>
          <p>NFT Price Sold</p>
          <p>Date Sold</p>
          <p>Wallet ID Purchased</p>
        </div>
        {listNftsSales.length ? (
          listNftsSales.map((nftInfo, index) => (
            <div className={'nft-info-item table-body'} key={index}>
              <p>{nftInfo.assetName}</p>
              <p>{nftInfo.assetDescription}</p>
              <p>{nftInfo.price}</p>
              <p>{nftInfo.date && getLocalDate(nftInfo.date as Date)}</p>
              <p>{nftInfo.buyerWallet}</p>
            </div>
          ))
        ) : (
          <div className="nft-info-item__no-info">
            <p>No info</p>
          </div>
        )}
      </div>
      <SpecificRoomPage />
    </div>
  );
}

export default SpecificRoom;
