import React from 'react';
import './EmployeeItem.scss';
import Button from '../_common/Button/Button';

interface Props {
  email: string;
  date: Date;
  token?: string;
  clickHandler?: () => void;
}

function EmployeeItem({ email, date, token, clickHandler }: Props) {
  return (
    <div
      style={token ? { gridTemplateColumns: '1fr 250px 185px' } : { gridTemplateColumns: '1fr 185px' }}
      className={'employee-item'}
    >
      <p className={'employee-item_email'}>{email}</p>
      <p className={'employee-item_date'}>{date.toString()}</p>
      {token && clickHandler && (
        <div className={'employee-item_actions'}>
          <Button className={'revoke_button'} title={'Confirm'} clickHandler={clickHandler} />
        </div>
      )}
    </div>
  );
}

export default EmployeeItem;
