import React from 'react';
import './CardChart.scss';

interface Props {
  name: string;
  value: number;
  percentage?: number;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  color: string;
  formatNumberType: 'currency' | 'number';
}

const findLengthAfterTheDot = (number: number) => {
  const numberString = number.toString();
  const dotIndex = numberString.indexOf('.');
  const lengthAfterTheDot = numberString.length - dotIndex - 1;

  return lengthAfterTheDot;
};

const CardChart = ({ name, value, percentage, icon, color, formatNumberType }: Props) => {
  const Icon = icon;
  let formatter;

  if (formatNumberType === 'currency') {
    formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'ETH',
      minimumFractionDigits: findLengthAfterTheDot(value),
    });
  } else {
    formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
    });
  }

  return (
    <div className="card-chart">
      <div className="card-chart__inner">
        <div className="card-chart__icon" style={{ backgroundColor: color }}>
          {<Icon width={30} height={30} />}
        </div>
        <div className="card-chart__coentent">
          <h3 className="card-chart__name">{name || 'Default name'}</h3>
          <p className="card-chart__value">
            {formatter.format(value)}{' '}
            {percentage && (
              <span className={`card-chart__value-percentage ${percentage > 0 ? 'positive' : 'negative'}`}>
                {percentage > 0 ? '+' : ''}
                {percentage || 0}%
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CardChart;
