import ROOMS_ACTION_TYPES from '../actionTypes/RoomsActionTypes';
import { NftByRoom, Room, RoomSizeInfoItem } from '../../types/rooms';

export const actionGetRooms = (data: Room[]) => {
  return {
    type: ROOMS_ACTION_TYPES.GET_ROOMS,
    data,
  };
};

export const actionFindRoom = (data: Room[]) => {
  return {
    type: ROOMS_ACTION_TYPES.FIND_ROOM,
    data,
  };
};

export const actionGetSpecificRoom = (data: Room[]) => {
  return {
    type: ROOMS_ACTION_TYPES.GET_SPECIFIC_ROOM,
    data,
  };
};

export const actionGetNftByRoom = (data: NftByRoom[]) => {
  return {
    type: ROOMS_ACTION_TYPES.GET_ROOM_NFT_DETAILS,
    data,
  };
};

export const actionGetRoomSize = (data: RoomSizeInfoItem[]) => {
  return {
    type: ROOMS_ACTION_TYPES.GET_ROOM_SIZE,
    data,
  };
};
