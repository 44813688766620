import React from 'react';
import './AnalyticsPage.scss';
import useCharts from '../../hooks/useCharts';
import { Field, Form, Formik, FormikErrors, FormikValues } from 'formik';
import Button from '../../components/_common/Button/Button';
import analytics from '../../assets/static/images/analytics.png';

const validate = (values: FormikValues) => {
  const errors: FormikErrors<FormikValues> = {};

  if (!values.startDate) {
    errors.startDate = 'Required';
  } else if (!values.endDate) {
    errors.endDate = 'Required';
  }

  return errors;
};

const AnalyticsPage = () => {
  const { getNumberOfUsers, getNumberOfSoldNft, getNumberOfRooms, getNumberOfPlacedNFT } = useCharts();

  const rangeInputs = () => {
    return (
      <Formik
        initialValues={{ startDate: '', endDate: '' }}
        validate={validate}
        onSubmit={(values) => {
          getNumberOfUsers({ beforedate: values.endDate.toString(), afterdate: values.startDate.toString() });
          getNumberOfSoldNft({ todate: values.endDate.toString(), fromdate: values.startDate.toString() });
          getNumberOfRooms({ beforedate: values.endDate.toString(), afterdate: values.startDate.toString() });
          getNumberOfPlacedNFT({ beforedate: values.endDate.toString(), afterdate: values.startDate.toString() });
        }}
      >
        {({ handleChange, values }) => {
          return (
            <Form className={'analytics-form'}>
              <p>From</p>
              <p>To</p>
              <span />
              <Field
                type={'date'}
                className={'form_input'}
                onChange={handleChange('startDate')}
                value={values.startDate}
                name={'startDate'}
              />
              <Field
                name={'endDate'}
                type={'date'}
                className={'form_input'}
                onChange={handleChange('endDate')}
                value={values.endDate}
              />
              <Button className={'dateConfirm'} title={'Apply'} type={'submit'} disabled={true} />
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <div className={'analytics'}>
      <div className={'analytics-header'}>
        <h2>Range Selection</h2>
        <div className={'range-container'}>{rangeInputs()}</div>
      </div>
      <div className={'analytics-charts'}>
        <img src={analytics} />
        {/*<DoughnutChart labels={['female', 'male']} data={[20, 50]} title={'Gender distribution'} />*/}
        {/*<VerticalBarChart*/}
        {/*  labels={['18-24', '25-34', '35-44', '45-54', '55-64', '65+']}*/}
        {/*  data={[10, 50, 20, 34, 100, 5]}*/}
        {/*  title={'Age distribution'}*/}
        {/*/>*/}
        {/*<VerticalBarChart*/}
        {/*  title={'Channels'}*/}
        {/*  labels={['Direct', 'Email', 'Referrals', 'Social', 'Organic search', 'Paid search', 'Display ads']}*/}
        {/*  data={[120, 30, 60, 10, 80, 15, 80]}*/}
        {/*/>*/}
      </div>
    </div>
  );
};

export default AnalyticsPage;
