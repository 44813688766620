import * as yup from 'yup';

const confirmPassword = yup.object({
  password: yup
    .string()
    .min(8, ' ')
    .matches(/(?=.*[A-Z])/, ' ')
    .matches(/(?=.*[0-9])/, ' ')
    .matches(/(?=.*[!@#\$%\^&\*])(?=.{8,})/, ' ')
    .required('Is required'),
  confirm: yup
    .string()
    .oneOf([yup.ref('password'), null], ' ')
    .required('Is required'),
});

export default confirmPassword;
