import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import './Modal.scss';
import SVG_ICONS from '../../../constants/SVG';

interface IModalProps {
  open: boolean;
  onClose: () => void;
  children: JSX.Element | JSX.Element[];
}

const Modal: FC<IModalProps> = ({ open, onClose, children }) => {
  if (!open) {
    return null;
  }

  return createPortal(
    <>
      <div className={'modal__overlay'} />
      <div className={'modal__inner'}>
        <button className={'modal__close-btn'} type={'button'} onClick={onClose}>
          <img src={SVG_ICONS.CLOSE} alt="Close button" />
        </button>
        {children}
      </div>
    </>,
    document.getElementById('modal') as Element
  );
};

export default Modal;
